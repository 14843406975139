import { Group } from 'three';

import MaterialObject from '../../classes/materialObject.js';
import Line from '../../objects/line.js';

export default class ScaleLines extends MaterialObject {
    constructor(lineSize, lineHeight, lineColor, {x = null, y = null, z = null}={x:null, y:null, z:null},{rx = null, ry = null, rz = null}={rx:null, ry:null, rz:null}) {
        super();
        
        this.obj = new Group();

        for(let i = 0; i <= 100; i += 10) {
            this.obj.add(
                new Line(lineSize, lineHeight, lineColor, {x: 50, y: 0}, {rz: Math.PI/2})
            );
        }

        this.setPosition(x, y, z);
        this.setRotation(rx, ry, rz);
    }
}