import { Scene, PerspectiveCamera, WebGLRenderer, AxesHelper, DirectionalLight, AmbientLight } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { SVGRenderer } from 'three/examples/jsm/renderers/SVGRenderer';

import GridPlane from '../objects/gridPlane.js';
import Compass from '../objects/compoundObjects/compass.js';
import CameraType from '../enums/cameraType.js';
import RenderType from '../enums/renderType.js';

let CAMERA_TYPE = CameraType.FREE_RANGE;
let CAMERA_POSITION = {x: 0, y: 0, z: 0};
let CAMERA_ROTATION = {x: 0, y: 0, z: 0};
let CAMERA_FOCALPOINT = {x: 0, y: 0, z: 0};
let CAMERA_FOV = 45;

export default class Stage {
    constructor(elm, _settings={}) {
        this.settings = _settings;

        CAMERA_TYPE = _settings.stage.cameraType || CAMERA_TYPE;
        CAMERA_POSITION = _settings.stage.cameraPosition || CAMERA_POSITION;
        CAMERA_ROTATION = _settings.stage.cameraRotation || CAMERA_ROTATION;
        CAMERA_FOCALPOINT = _settings.stage.cameraFocalPoint || CAMERA_FOCALPOINT;
        CAMERA_FOV = _settings.stage.cameraFov || CAMERA_FOV;

        if(_settings.stage.cameraPreset !== null && typeof _settings.stage.cameraPreset !== "undefined") {
            CAMERA_TYPE = _settings.stage.cameraPreset.cameraType || CAMERA_TYPE;
            CAMERA_POSITION = _settings.stage.cameraPreset.cameraPosition || CAMERA_POSITION;
            CAMERA_ROTATION = _settings.stage.cameraPreset.cameraRotation || CAMERA_ROTATION;
            CAMERA_FOCALPOINT = _settings.stage.cameraPreset.cameraFocalPoint || CAMERA_FOCALPOINT;
            CAMERA_FOV = _settings.stage.cameraPreset.cameraFov || CAMERA_FOV;
        }

        this.elm = elm;
        this.controls = null;

        this.scene = new Scene();

        this.canvas = this.setCanvas();
        this.context = this.setContext(this.canvas, _settings.renderType);
        this.renderer = this.setRenderer(this.elm, this.canvas, this.context, _settings.renderType);
        this.camera = this.setCamera(this.renderer);

        const light = new AmbientLight(0xffffff, 1.8);
        this.scene.add(light);

        const directionalLight = new DirectionalLight(0xffffff, 0.3);
        directionalLight.position.set(300, 300, 200);
        this.scene.add(directionalLight);

        this.elm.appendChild(this.renderer.domElement);

        this.init();
    }

    init() {
        if(this.settings.debug) {

            if(this.settings.debugOptions.showAxes) {
                this.setupAxes();
            }

            if(this.settings.debugOptions.showGrid) {
                this.setupGridPlane();
            }
            
            this.setupCompass();
        }
    }

    setCanvas() {
        const canvas = document.createElement( 'canvas' );
        canvas.style = 'background: #ffffff;';
        return canvas;
    }

    setContext(canvas, type) {
        if(type !== RenderType.WEBGL2) {
            return null;
        }

        return canvas.getContext( 'webgl2' );
    }

    setRenderer(elm, canvas, context, type) {
        let renderer = null;

        switch(type) {
            case RenderType.WEBGL:
                renderer = new WebGLRenderer({ canvas: canvas });
            break;
            case RenderType.WEBGL2:
                renderer = new WebGLRenderer({ canvas: canvas, context: context });
            break;
            case RenderType.SVG:
                renderer = new SVGRenderer();
            break;
        }
        
        renderer.setSize(elm.clientWidth, elm.clientHeight);
        renderer.setClearColor( 0xffffff, 1 );
        
        return renderer;
    }

    setCamera(renderer=null) {
        if(renderer === null) {
            throw Error("Renderer must be set first");
        }

        let camera = null;

        switch(CAMERA_TYPE) {
            case CameraType.ORBIT:
                //this.controls.update() must be called after any manual changes to the camera's transform
                camera = new PerspectiveCamera(CAMERA_FOV, this.elm.clientWidth / this.elm.clientHeight, 0.1, 1000);
                    
                    this.controls = new OrbitControls(camera, renderer.domElement);
                    this.controls.target.x = CAMERA_FOCALPOINT.x;
                    this.controls.target.y = CAMERA_FOCALPOINT.y;
                    this.controls.target.z = CAMERA_FOCALPOINT.z;

                    camera.position.x = CAMERA_POSITION.x;
                    camera.position.y = CAMERA_POSITION.y;
                    camera.position.z = CAMERA_POSITION.z;

                    camera.rotation.x = CAMERA_ROTATION.x;
                    camera.rotation.y = CAMERA_ROTATION.y;
                    camera.rotation.z = CAMERA_ROTATION.z;
                break;
            case CameraType.FREE_RANGE:
            default:
                camera = new PerspectiveCamera(CAMERA_FOV, this.elm .clientWidth/this.elm .clientHeight, 0.1, 1000 );

                    camera.position.x = CAMERA_POSITION.x;
                    camera.position.y = CAMERA_POSITION.y;
                    camera.position.z = CAMERA_POSITION.z;

                    camera.rotation.x = CAMERA_ROTATION.x;
                    camera.rotation.y = CAMERA_ROTATION.y;
                    camera.rotation.z = CAMERA_ROTATION.z;
                break;
        }

        return camera
    }

    setCameraView() {
        this.camera
    }

    setupAxes() {
        this.scene.add(
            new AxesHelper( 200 )
        );
    }

    setupGridPlane() {
        this.scene.add( 
            new GridPlane(300, 30)
        );
    }

    setupCompass() {
        this.scene.add(
            new Compass(100, 0xff0000, {x: 0, y: 1, z: 0}, {rx: Math.PI / 2, ry: 0, rz: Math.PI})
        );
    }
}