import merge from 'webpack-merge';
import Visualization from '../classes/visualization.js';
import Cube from '../objects/compoundObjects/cube.js';
import Pointer from '../objects/compoundObjects/pointer.js';

const DEFAULTS = {
    visualization: {
        scaleStart: 0,
        scaleEnd: 100,
        scaleRender: 100,
        colors: {
            corner: 0x162625,
            disc: 0x949599,
            plane: 0xb2c0c9,
            grid: 0xffffff
        },
        sizes: {
            corner: 3,
            disc: 3,
            edge: 1.5,
            pointTip: 4,
            pointShaft: 1
        },
    },
    data: {
        axes: {
            x: {
                name: 'Attachment',
                anchor1: {
                    name: 'Matter-Attachment',
                    color: 0x437832
                },
                anchor2: {
                    name: 'People-Attachment',
                    color: 0xe5ac00
                },
            },
            y: {
                name: 'Maturity in Complexity',
                anchor1: {
                    name: 'Self-expressive',
                    color: 0x808080
                },
                anchor2: {
                    name: 'Integrative',
                    color: 0x808080
                },
            },
            z: {
                name: 'Exploration',
                anchor1: {
                    name: 'Stability',
                    color: 0x114f8d
                },
                anchor2: {
                    name: 'Exploration',
                    color: 0xa00114
                },
            },
        },
        pointers: []
    }
};

DEFAULTS.visualization.scale = DEFAULTS.visualization.scaleEnd - DEFAULTS.visualization.scaleStart;

export default class AEMCube extends Visualization {
    constructor(_settings={}) {
        let settings = merge(DEFAULTS, _settings);
        super(settings);

        this.pointers = new Map();
    
        this.init();

        this.ready();
    }

    init() {
        this.addCube();
        this.initPointers();
    }

    onFrameExecution() {
        if(this.settings.debug) {
            this.debugInfo = `${this.stage.camera.position.x}${this.stage.camera.position.y}${this.stage.camera.position.z}${this.stage.camera.rotation.x}${this.stage.camera.rotation.y}${this.stage.camera.rotation.z}`;
            console.log("debug?");
            if(this.debugInfo !== this.debugInfoCache) {
                console.log(`camera:(
                    position:
                    x: ${this.stage.camera.position.x} 
                    y:  ${this.stage.camera.position.y} 
                    z: ${this.stage.camera.position.z}
                    rotation: 
                    x: ${this.stage.camera.rotation.x} 
                    y:  ${this.stage.camera.rotation.y} 
                    z: ${this.stage.camera.rotation.z}
                )`);

                this.debugInfoCache = this.debugInfo;
            }
        }
    }

    addCube() {
        this.cube = new Cube(
            this.settings.visualization.scaleRender, 
            this.settings.visualization.colors.plane, 
            this.settings.visualization.colors.grid, 
            this.settings.visualization.colors.edge,
            [
                this.settings.data.axes.z.anchor2.color, 
                this.settings.data.axes.x.anchor2.color, 
                this.settings.data.axes.z.anchor1.color, 
                this.settings.data.axes.x.anchor1.color
            ], 
            this.settings.data.axes.y.anchor1.color, 
            this.settings.visualization.sizes.disc, 
            this.settings.visualization.colors.disc,
            this.settings.visualization.sizes.corner, 
            [
                this.settings.visualization.colors.corner, 
                this.settings.visualization.colors.corner, 
                this.settings.visualization.colors.corner, 
                this.settings.visualization.colors.corner
            ],
            { 
                x: -this.settings.visualization.scaleRender*.5, 
                z: this.settings.visualization.scaleRender*.5
            }
        );

        this.stage.scene.add(this.cube);
    }

    initPointers() {
        this.settings.data.pointers.forEach((pointer) => {
            this.addPointer(pointer.name, pointer.color, pointer.type, pointer.percentiles.x, pointer.percentiles.y, pointer.percentiles.z)
        });
    }

    addPointer(name, color, pointerType, _percentileX, _percentileY, _percentileZ) {
        const translate = (p) => { return (this.settings.visualization.scaleRender * (p-this.settings.visualization.scaleStart)) / this.settings.visualization.scale; }; 

        const percentileX = translate(this.boundPercentileToScale(_percentileX));
        const percentileY = translate(this.boundPercentileToScale(_percentileY));
        const percentileZ = translate(this.boundPercentileToScale(_percentileZ));
        
        const pointer = new Pointer(percentileY, this.settings.visualization.sizes.pointShaft, this.settings.visualization.sizes.pointTip, color, color, pointerType, {x: percentileX, z: -percentileZ});
        this.cube.add(pointer);

        this.pointers.set(name, pointer);
    }

    boundPercentileToScale(percentile) {
        if(percentile < this.settings.visualization.scaleStart) {
            percentile = this.settings.visualization.scaleStart;
        }

        if(percentile > this.settings.visualization.scaleEnd) {
            percentile = this.settings.visualization.scaleEnd;
        }

        return percentile;
    }

    removePointer(name) {
        this.pointers.remove(name);
    }
}