import { CylinderGeometry, MeshBasicMaterial, Mesh, MeshPhysicalMaterial, EdgesGeometry, LineBasicMaterial, LineSegments } from 'three';
import MaterialObject from '../classes/materialObject.js'

export default class Line extends MaterialObject {

    constructor(radius, length, color, { x = null, y = null, z = null } = { x: null, y: null, z: null }, { rx = null, ry = null, rz = null } = { rx: null, ry: null, rz: null }, basicMat = false, edges = false) {
        super();
        
        const geometry = new CylinderGeometry( radius, radius, length, 32 );
        const material = basicMat ? new MeshBasicMaterial({ color: color }) : new MeshPhysicalMaterial({ color: color })
        this.obj = new Mesh(geometry, material);

        if (edges) {
            const geo = new EdgesGeometry(this.obj.geometry);
            const mat = new LineBasicMaterial({ color: '#ffffff' });
            const wireframe = new LineSegments(geo, mat);
            this.obj.add(wireframe);
        }

        this.setPosition(x, y, z);
        this.setRotation(rx, ry, rz);

        return this.obj;
    }
}