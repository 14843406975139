import * as Const from '../const';

const LANGUAGE_API_URL = '/api/humaninsight/instrument/{instrumentID}/instrumentitem/directrelationdata?columnName=LanguageID';
const ITEMS_API_URL = '/api/humaninsight/instrument/{instrumentID}/instrumentitem/rows';
const ORDER_API_URL = '/api/humaninsight/instrument/{instrumentID}/instrumentitem/rows/order';
const DELETE_API_URL = '/api/humaninsight/instrument/{instrumentID}/instrumentitem/rows/delete'; //pass array of UID's
const DETAIL_LINK = '/instruments/{instrumentID}/items/{ID}';

//TODO:
//1. store language in localStorage + init language
//2. store order via api

export default class InstrumentItemList {
    constructor(elm) {
        this.$elm = $(elm);
        this.data = this.$elm.data();

        this.languageId = localStorage.getItem(Const.LANGUAGE_STORAGE_KEY) || Const.LANGUAGE_DEFAULT_VAL;
        this.init();
    }

    init() {
        this.setEventListeners();

        this.loadLanguages();
        this.loadItems(this.languageId);
    }

    setEventListeners() {
        $(document)
            .on('click', '#InstrumentItemList .media-single', this.onItemClick.bind(this))
            .on('click', '#InstrumentItemList .deleteItems', this.onDeleteItemsClick.bind(this))
            .on('click', '.InstrumentItemList-storeOrder', this.onStoreOrderClick.bind(this))
            .on('click', '#InstrumentItemList input[type="checkbox"]', event => { event.stopPropagation(); }) //prevent deeplink
            .on('change', '#item-language-id', this.onItemLanguageIdChange.bind(this));

        //TODO: find out how to trigger the onchange on the sortable and fire: storeOrder(ids);
    }

    loadItems(languageId) {
        localStorage.setItem(Const.LANGUAGE_STORAGE_KEY, languageId);
        this.languageId = languageId;

        $.ajax({
            url: ITEMS_API_URL.replace('{instrumentID}', this.data.parentid),
            type: 'GET',
            data: {
                languageID: languageId
            },
            success: (data) => {
                let html = '';
                
                for (let item of data) {
                    const id = item['id'];

                    if (id === null || id === '') {
                        return;
                    }

                    let rowHtml = `<div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input row-selector" name="InstrumentItemListAction" value="${id}">
                                        <label class="custom-control-label"></label>
                                    </div>`;

                    for (let key of Object.keys(item)) {
                        let value = item[key];
                        rowHtml += `<span class="${key}">${value}</span>`;
                    }

                    html += `
                        <div class="instrumentItemList-item media media-single cursor-pointer" data-id="${id}">
                            ${rowHtml}
                            <span class="handle ti-exchange-vertical float-right"></span>
                        </div>
                    `;
                }

                $('#InstrumentItemListSortable').html(html);

                window.provider.initSortable();
                this.updateResultString(data.length, data.length);
            },
            error: function(e) {
                console.warn(e);
            }
        });
    }

    loadLanguages() {
        $.ajax({
            url: LANGUAGE_API_URL.replace('{instrumentID}', this.data.parentid),
            type: 'GET',
            success: (data) => {
                let html = '';
                for (let item of data) {
                    let selected = (this.languageId === item.value) ? ' selected="selected"' : '';
                    html += `<option value="${item.value}"${selected}>${item.text}</option>`;
                }

                $('#item-language-id').html(html);
            },
            error: function (e) {
                console.warn(e);
            }
        });
    }

    updateResultString(filteredCount=0, totalCount=0) {
        $('#InstrumentItemList .filteredcount').text(filteredCount);
        $('#InstrumentItemList .totalcount').text(totalCount);
    }

    storeOrder(ids='',) {
        $.ajax({
            url: ORDER_API_URL.replace('{instrumentID}', ''),
            data: {
                ids: ids
            },
            success: (data) => {
                app.toast('Changes saved');
            },
            error: (e) => {
                console.warn(e);
                //TODO: revert order?
            }
        });
    }

    deleteItems(ids=[]) {
        $.ajax({
            url: DELETE_API_URL.replace('{instrumentID}', this.data.parentid),
            type: 'post',
            data: JSON.stringify(ids),
            dataType: 'json',
            contentType: 'application/json',
            success: (data) => {
                if (ids.length === 0) {
                    $('#modal-error .modal-body').html(`<p>Please select items to delete first.</p>`);
                    $('[data-target="#modal-error"]').trigger('click');
                    return;
                }

                const attributeSelectors = ids.map((id) => {
                    return `.instrumentItemList-item[data-id="${id}"]`;
                });

                $(attributeSelectors.join(',')).hide(200);
            },
            error: (e) => {
                console.warn(e);

                $('#InstrumentItemList .closeModal').trigger('click');
                $('#modal-error .modal-body').html(``);
                $('[data-target="#modal-error"]').trigger('click');
            },
            complete: () => {
                $('#InstrumentItemList .closeModal').trigger('click');

            }
        });
    }

    storeOrder(items) {
        $.ajax({
            url: ORDER_API_URL.replace('{instrumentID}', this.data.parentid),
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(items),
            success: (data) => {
                app.toast('Changes saved');
                console.log(data);
            },
            error: (e) => {
                console.warn(e);
            }
        });
    }

    onItemClick(event) {
        event.preventDefault();

        let id = $(event.target).closest('.media-single').find('.id').text();

        window.location.href = DETAIL_LINK.replace('{instrumentID}', this.data.parentid).replace('{ID}', id);
    }

    onItemLanguageIdChange(event) {
        event.preventDefault();

        let $target = $(event.target);
        var languageId = $target.val();

        this.loadItems(languageId);
    }

    onDeleteItemsClick(event) {
        event.preventDefault();

        let items = [];

        $('#InstrumentItemList input[name="InstrumentItemListAction"]:checked').each((i, elm) => {
            items.push($(elm).val());
        });

        this.deleteItems(items);
    }

    onStoreOrderClick(event) {
        event.preventDefault();

        let items = {};
        $('.instrumentItemList-item').each((i, elm) => {
            let $elm = $(elm);
            let id = $elm.data('id');
            let index = i + 1;

            items[id] = index;
        });

        this.storeOrder(items);
    }
}