export default class ConsultantDetail {
    constructor(elm) {
        this.elm = elm;
        this.init();

        
    }
    init() {

        this.setEventListeners();
    }
    setEventListeners() {
        $('#consultant-impersonate').click(this.impersonateConsultant);
    }
  
    impersonateConsultant() {
        var email = $(this).data('email');
        Glide.users.impersonate(email, {
            success: function () {
                window.location.href = '/';
            },
            error: function (e) {
                Glide.presentation.card.showError(this, e.responseText, e);
            }
        });
    }
}