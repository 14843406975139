import Helper from '../helper.js';

export default class HandlebarsContainer {
    constructor(elm, container) {
        var $elm = $(elm);
        this.template = Handlebars.compile($elm.html());

        if (container) {
            this.container = container;
        } else {
            this.container = $elm.parent();
        }

        this.registerHelpers();
    }

    registerHelpers() {
        Handlebars.registerHelper("select", function (value, options) {
            return options.fn(this)
                .split('\n')
                .map(function (v) {
                    var t = 'value="' + value + '"';
                    return !RegExp(t).test(v) ? v : v.replace(t, t + ' selected="selected"');
                })
                .join('\n');
        });
    }

    renderWhen(promise) {
        promise.done(this.render.bind(this));
    }

    render(data) {
        var content = this.template(data);
        this.container.html(content);

        Helper.initFastselect($('.fastselect'));
    }

    getTemplate() {
        return this.template;
    }
}
