import { SphereGeometry, MeshBasicMaterial, Mesh, MeshPhysicalMaterial, EdgesGeometry, LineBasicMaterial, LineSegments } from 'three';
import MaterialObject from '../classes/materialObject.js'

export default class Ball extends MaterialObject {
    constructor(radius, color, { x = null, y = null, z = null } = { x: null, y: null, z: null }, basicMat = false, edges = false) {
        super();
        
        const geometry = new SphereGeometry( radius, 32, 32 );
        const material = basicMat ? new MeshBasicMaterial({ color: color }) : new MeshPhysicalMaterial({ color: color })
        this.obj = new Mesh( geometry, material );

        if (edges) {
            const geo = new EdgesGeometry(this.obj.geometry);
            const mat = new LineBasicMaterial({ color: '#ffffff' });
            const wireframe = new LineSegments(geo, mat);
            this.obj.add(wireframe);
        }

        this.setPosition(x, y, z);

        return this.obj;
    }
}