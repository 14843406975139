import { Group } from 'three';
import MaterialObject from '../../classes/materialObject.js';
import Floor from './floor.js';
import Frame from './frame.js';
import Pillar from './pillar.js';

export default class Cube extends MaterialObject {
    constructor(scale, floorColor, gridColor, lineSize, lineColors, pillarColor, discSize, discColor, cornerSize, cornerColors, {x = null, y = null, z = null}={x:null, y:null, z:null},{rx = null, ry = null, rz = null}={rx:null, ry:null, rz:null}) {
        super();

        this.obj = new Group();

        this.obj.add(new Floor(scale, scale, floorColor, gridColor, {x: scale*.5, y: 0, z: -(scale*.5)}));
        this.obj.add(this.getPillars(lineSize, scale, pillarColor, discSize, discColor));
        this.obj.add(new Frame(lineSize, scale, [lineColors[0], lineColors[1], lineColors[2], lineColors[3]], cornerSize, [cornerColors[0],cornerColors[1],cornerColors[2],cornerColors[3]]));
        this.obj.add(new Frame(lineSize, scale, [lineColors[0], lineColors[1], lineColors[2], lineColors[3]], cornerSize, [cornerColors[0],cornerColors[2],cornerColors[3],cornerColors[3]],{y: scale}));

        this.setPosition(x, y, z);
        this.setRotation(rx, ry, rz);

        this.obj.castShadow = true;
        this.obj.receiveShadow = true;

        return this.obj;
    }

    getPillars(lineSize, scale, pillarColor, discSize, discColor) {
        const discHeight = () => { return discSize * .5; };

        const pillars = new Group();

        pillars.add(new Pillar(lineSize, scale, pillarColor, discSize, discHeight(), discColor, [.25, .5, .75]));
        pillars.add(new Pillar(lineSize, scale, pillarColor, discSize, discHeight(), discColor, [.25, .5, .75], {x: scale}));
        pillars.add(new Pillar(lineSize, scale, pillarColor, discSize, discHeight(), discColor, [.25, .5, .75], {z: -scale}));
        pillars.add(new Pillar(lineSize, scale, pillarColor, discSize, discHeight(), discColor, [.25, .5, .75], {x: scale, z: -scale}));

        return pillars;
    }
}