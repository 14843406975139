import { CylinderGeometry, MeshBasicMaterial, Mesh, MeshPhysicalMaterial, EdgesGeometry, LineBasicMaterial, LineSegments } from 'three';
import MaterialObject from '../classes/materialObject.js'

export default class Disc extends MaterialObject {
    constructor(radius, height, color, { x = null, y = null, z = null }, basicMat = false, edges = false) {
        super();
        
        const geometry = new CylinderGeometry( radius, radius, height, 32 );
        const material = basicMat ? new MeshBasicMaterial({ color: color }) : new MeshPhysicalMaterial({ color: color })
        this.obj = new Mesh(geometry, material);

        if (edges) {
            const geo = new EdgesGeometry(this.obj.geometry);
            const mat = new LineBasicMaterial({ color: '#ffffff' });
            const wireframe = new LineSegments(geo, mat);
            this.obj.add(wireframe);
        }

        this.setPosition(x, y, z);

        return this.obj;
    }
}