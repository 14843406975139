import { Shape, ShapeGeometry, MeshBasicMaterial, Mesh, Group } from 'three';
import Ball from './ball.js';
import MaterialObject from '../classes/materialObject.js'

export default class MyShape extends MaterialObject {

    constructor(index, offsetLeft, offsetRight, offsetTop, offsetBottom, {x = null, y = null, z = null}={x: null, y: null, z: null}, {rx = null, ry = null, rz = null}={rx: null, ry: null, rz: null}, debug=false) {
        super();
        
        this.index = index;
        this.offsetLeft = offsetLeft;
        this.offsetRight = offsetRight;
        this.offsetTop = offsetTop;
        this.offsetBottom = offsetBottom;

        this.obj = new Group();

        const triangleShape = this.getShape();
        const geometry = new ShapeGeometry( triangleShape );
        const material = new MeshBasicMaterial( { color: 0x00ff00 } );
        this.obj.add(new Mesh( geometry, material ));

        if(debug) {
            this.setDebugInfo();
        }

        this.setPosition(x, y, z);
        this.setRotation(rx, ry, rz);

        return this.obj;
    }

    getShape() {
        const shape = new Shape();
        shape.moveTo( this.index, this.offsetTop );
        shape.lineTo( this.index+this.offsetRight, 0 );
        shape.lineTo( this.index, -this.offsetBottom );
        shape.lineTo( this.index-this.offsetLeft, 0 );

        return shape;
    }

    setDebugInfo() {
        this.obj.add(
            new Ball(.5, 0x382948, {x:this.index,y:this.offsetTop})
        );
        this.obj.add(
            new Ball(.5, 0x382948, {x:this.index+this.offsetRight,y:0})
        );
        this.obj.add(
            new Ball(.5, 0x382948, {x:this.index,y:-this.offsetBottom})
        );
        this.obj.add(
            new Ball(.5, 0x382948, {x:this.index-this.offsetLeft,y:0})
        );
    }
}