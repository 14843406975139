import HandlebarsTemplate from "./HandlebarsTemplate";

export default class ProjectGroupView {
    constructor(elm) {
        var $elm = $(elm);
        var memberList = $('#project-group-member-list-template');

        this.$form = $elm.find('.form-add-participant');
        this.$memberListContainer = $elm.find('#memberListContainer');
        this.memberList = new HandlebarsTemplate(memberList);

        this.controllerurl = $elm.data('apiurlbase');

        this.refreshList();
        this.setEventListeners();
    }

    setEventListeners() {
        this.$form.on('submit', this.addParticipantToGroup.bind(this));
        this.$memberListContainer.on('click', '.cat-delete', this.removeParticipantFromGroup.bind(this));
    }

    loadTemplate(data) {
        if (typeof Glide.resolveReferences === 'function') {
            data = Glide.resolveReferences(data);
        }

        this.memberList.render(data);
    }

    addParticipantToGroup(event) {
        event.preventDefault();

        $.ajax({
            url: this.controllerurl + '/rows',
            type: 'POST',
            data: this.$form.serialize(),
            success: this.refreshList.bind(this)
        });
    }

    removeParticipantFromGroup(event) {
        event.preventDefault();
        var id = $(event.currentTarget).data('id');

        $.ajax({
            url: this.controllerurl + '/rows/' + id,
            type: 'DELETE',
            success: this.refreshList.bind(this)
        });
    }

    refreshList() {
        var request = $.ajax({
            url: this.controllerurl + '/rows',
            type: 'GET'
        });

        request.done(this.loadTemplate.bind(this));
    }
}