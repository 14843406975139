import merge from 'webpack-merge';
import Visualization from '../classes/visualization.js';
import Line from '../objects/line.js';
import Pointer from '../objects/compoundObjects/pointer.js';

const DEFAULTS = {
    visualization: {
        scaleStart: 1,
        scaleEnd: 6,
        scaleRender: 100,
        colors: {
            line: 0xc9c9c9
        },
        sizes: {
            pointTip: 4,
            pointShaft: 1
        },
    }
};

DEFAULTS.visualization.scale = DEFAULTS.visualization.scaleEnd - DEFAULTS.visualization.scaleStart;

export default class Scale extends Visualization {
    constructor(_settings={}) {
        let settings = merge(DEFAULTS, _settings);
        super(settings);
        
        this.pointers = new Map();
        this.scaleStepSize = settings.visualization.scaleRender / (settings.visualization.scale + 1);

        this.addScale();

        this.init();

        this.ready();
    }

    init() {
        this.addScale();

        this.settings.data.pointers.forEach((pointer) => {
            this.addPointer(pointer.name, pointer.color, pointer.type, pointer.scaleStep);
        });
    }

    addScale() {
        const line = new Line(.4, 100, this.settings.visualization.colors.line, {x: 50, y: 50}, {rz: Math.PI/2});
        this.stage.scene.add(line);

        for(let i = 0; i <= this.settings.visualization.scale+1; i++) {
            const currentScaleStep = i * this.scaleStepSize;
            const line = new Line(.25, 5, this.settings.visualization.colors.line, {x: currentScaleStep, y: 52.5});
            this.stage.scene.add(line);
        }
    }

    //onFrameExecution() {
    //}

    addPointer(name, color, pointerType, scaleStep) {
        const x = ((scaleStep-1) * this.scaleStepSize) + (this.scaleStepSize * .5) - (this.settings.visualization.sizes.pointShaft * .5);
        var pointer = new Pointer(30, this.settings.visualization.sizes.pointShaft, this.settings.visualization.sizes.pointTip, color, color, pointerType, {x: x, z: 0}, 50);
        this.stage.scene.add(pointer);

        this.pointers.set(name, pointer);
    }

    removePointer(name) {
        this.pointers.remove(name);
    }
}