const cheerio = require('cheerio');

const TYPES = {
    ajax: 'ajax',
    iframe: 'iframe'
};

export default class QuickView {
    constructor(elm) {
        this.$elm = $(elm);
        this.id = this.$elm.attr('href').replace('#','');
        this.$targetPanel = $(`.QuickView-target[data-targetid="${this.id}"]`);
        this.$targetLoader = this.$targetPanel.find('.QuickView-targetLoader');
        this.$targetContent = this.$targetPanel.find('.QuickView-targetContent');
        this.data = this.$elm.data();

        this.resultContext = this.data.boresultcontext || 'body';
        this.type = this.data.botype || 'ajax';

        this.setEventListeners();
    }

    setEventListeners() {
        this.$elm.on('click', this.onTriggerClick.bind(this));
    }

    loadView() {
        switch (this.type) {
            case TYPES.ajax:
                this.loadHtml();
                break;
            case TYPES.iframe:
                this.loadIframe();
                break;
        }
    }

    loadIframe() {
        var iframeElement = document.createElement('iframe');
        iframeElement.id = 'testiframe';
        iframeElement.src = this.data.boresourceurl;
        iframeElement.setAttribute('style', 'width: 0; height: 0; border: none;');
        iframeElement.setAttribute('height', '0');
        iframeElement.setAttribute('width', '0');

        this.$targetContent[0].appendChild(iframeElement);

        iframeElement.addEventListener("load", () => {
            const $$ = cheerio.load(iframeElement.contentWindow.document.getElementsByTagName('html')[0].innerHTML);
            var html = $$.html(this.resultContext);
            var scripts = $$.html('.scripts');


            $$('body').html(html + scripts);

            iframeElement.contentWindow.document.write($$.html());

            iframeElement.setAttribute('style', 'width: 100%; height: 100%; border: none;');
            iframeElement.setAttribute('height', '100%;');
            iframeElement.setAttribute('width', '100%;');

            this.$targetLoader.hide();
        });
    }

    loadHtml() {
        $.ajax({
            url: this.data.boresourceurl,
            data: {
                ajax: true
            },
            success: (html) => {
                const $$ = cheerio.load(html);

                this.$targetContent.html($$.html(this.resultContext));
                this.$targetLoader.hide();
            }
        });
    }

    onTriggerClick(event) {
        setTimeout(() => {
            this.loadView();
        }, 200);
    }
}