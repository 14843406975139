import { Group } from 'three';
import MaterialObject from '../../classes/materialObject.js';
import Line from '../line.js';
import Ball from '../ball.js';

var rotate90 = () => Math.PI / 2;

export default class Frame extends MaterialObject {
    constructor(lineSize, lineLength, lineColors, cornerSize, cornerColors, {x = null, y = null, z = null}={x:null, y:null, z:null},{rx = null, ry = null, rz = null}={rx:null, ry:null, rz:null}) {
        super();

        this.obj = new Group();

        this.lineSize = lineSize;
        this.lineLength = lineLength;
        this.lineColors = lineColors;
        this.cornerSize = cornerSize;
        this.cornerColors = cornerColors;

        this.addLines();
        this.addCorners();

        this.setPosition(x, y, z);
        this.setRotation(rx, ry, rz);

        return this.obj;
    }

    addLines() {
        const lines = new Group();
        //back
        lines.add(new Line(this.lineSize, this.lineLength, this.lineColors[0], {x: this.lineLength*.5, z: -this.lineLength}, {rz: rotate90()}));
        //right
        lines.add(new Line(this.lineSize, this.lineLength, this.lineColors[1], {x: this.lineLength, z: -(this.lineLength-this.lineLength*.5)}, {ry: rotate90(), rz: rotate90()}));
        //front
        lines.add(new Line(this.lineSize, this.lineLength, this.lineColors[2], {x: this.lineLength*.5, z: 0}, {rz: rotate90()}));
        //left
        lines.add(new Line(this.lineSize, this.lineLength, this.lineColors[3], {z: -(this.lineLength-this.lineLength*.5)}, {rx: 0, ry: rotate90(), rz: rotate90()}));

        this.obj.add(lines);
    }

    addCorners() {
        const corners = new Group();    
        //frontleft
        corners.add(new Ball(this.cornerSize, this.cornerColors[0], { x: 0, y: 0, z: 0}));
        //frontright
        corners.add(new Ball(this.cornerSize, this.cornerColors[1], { x: this.lineLength, y: 0, z: 0}));
        //backleft
        corners.add(new Ball(this.cornerSize, this.cornerColors[2], { x: 0, y: 0, z: -this.lineLength}));
        //backright
        corners.add(new Ball(this.cornerSize, this.cornerColors[3], { x: this.lineLength, y: 0, z: -this.lineLength}));

        this.obj.add(corners);
    }
}