import { Group } from 'three';
import MaterialObject from '../../classes/materialObject.js';
import Line from '../line.js';
import Disc from '../disc.js';

export default class Pillar extends MaterialObject {
    constructor(lineSize, lineHeight, lineColor, discSize, discHeight, discColor, discPercentiles, {x = null, y = null, z = null}={x:null, y:null, z:null},{rx = null, ry = null, rz = null}={rx:null, ry:null, rz:null}) {
        super();

        var scalePos = (percentile) => { return 0 + (lineHeight * percentile); };

        this.obj = new Group();
        
        this.obj.add(
            new Line(lineSize, lineHeight, lineColor, {x: 0, y: lineHeight*.5, z: 0})
        );

        discPercentiles.forEach((discPercentile) => {
            this.obj.add(
                new Disc(discSize, discHeight, discColor, {x: 0, y: scalePos(discPercentile), z: 0})
            );
        });

        this.setPosition(x, y, z);
        this.setRotation(rx, ry, rz);

        return this.obj;
    }
}