import Helper from '../helper.js';
const LOAD_DATA_API_URL = '/api/humaninsight/project/{parentId}/session/rows';
const DELETE_API_URL = '/api/humaninsight/project/{parentId}/session/rows/delete';
const EDIT_API_URL = '/api/humaninsight/project/{parentId}/session/rows';
const NEW_URL = '/projects/{parentId}/sessions/new';
const DETAIL_URL = '/projects/{parentId}/sessions/{id}';
const EMAIL_API_URL = '/api/humaninsight/project/{parentId}/session/maildialog';
const EMAIL_SEND_API_URL = '/api/humaninsight/project/{parentId}/session/mailsend';

const COLUMNS = ['participantName', 'subject', 'type', 'instrumentAlias', 'expireAt', 'completed1', 'progress', 'status', 'lastMailSentType', 'lastMailSentDate'];
const COLUMNS_D = ['participantName', 'subject', 'type', 'instrumentAlias', 'expireAt', 'completed1', 'progress', 'status', 'lastMailSentType', 'lastMailSentDate']
const COLUMN_NAMES = ['Participant', 'Subject', 'Image type', 'Instrument', 'Expiry date', 'Progress', 'Completion percentage', 'Status', 'Last sent e-mail', 'E-mail date'];
const COLUMN_NAMES_D = ['Participant', 'Subject', 'Image type', 'Instrument', 'Expiry date', 'Progress', 'Completion percentage', 'Status', 'Last sent e-mail', 'E-mail date'];
const VALUES = ['participantName', 'subject', 'type', 'instrumentAlias', 'expireAt', 'completed1', 'progress', 'status', 'lastMailSendType', 'lastMailSendText', 'isExpired'];
const VALUES_D = ['participantName', 'subject', 'type', 'instrumentAlias', 'expireAt', 'completed1', 'progress', 'status', 'lastMailSendType', 'lastMailSendText', 'isExpired'];


const FILTER_COLUMNS = ['status', 'participantName', 'expireAt', 'subject', 'progress'];
const FILTER_COLUMN_NAMES = ['Status', 'Participant', 'Expiry date', 'Subject', 'Progress'];


let PROJECTID = "";
let sessionIDs = [];

const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

const SortDirection = {
    ASCENDING: 'ASC',
    DESCENDING: 'DESC'
};

const formatExpiryDate = (dateString) => {
    let m = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    let d = new Date(dateString);
    return `${d.getDate()} ${m[d.getMonth()]} ${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
}

const normalizeDate = (dateString) => {
    let d = new Date(dateString);
    d.setHours(0, 0, 0, 0);
    return d;
}

function parseDate(str) {
    var mdy = str.split('-');
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
}

function datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
}

export default class ProjectGroupView {
    constructor(elm) {

        this.$elm = $(elm);
        this.data = this.$elm.data();
        this.initStateGroupAndSort = 'subject';
        this.resultData = null;

        this.groupByColumn = '';
        this.filterColumns = null;
        this.sortByColumn = this.initStateGroupAndSort;
        this.sortDirection = SortDirection.ASCENDING;

        this.loadHeadings();
        this.setGroupByColumn(this.initStateGroupAndSort);
        this.loadData();

        this.setEventListeners();

        this.setFilterColumns([]);

        //scrollabe table
        this.chartTable = $('table#chart');
        this.chartWrapper = this.chartTable.parent();
        this.chartWidth = this.chartTable.width();
        this.chartHeight = this.chartTable.height();

        this.sizeChart();
    }

    setEventListeners() {
        $(document)
            .on('click', '.ProjectSessionsList-add', this.onAddClick.bind(this))
            .on('click', '.ProjectSessionsList-groupAll', this.onGroupAllClick.bind(this))
            .on('click', '.ProjectSessionsList-expandAll', this.onExpandAllClick.bind(this))
            .on('keyup', '.ProjectSessionsList-search', this.onSearchKeyUp.bind(this))
            .on('click', '.ProjectSessionsList-groupCancel', this.onCancelGroupingClick.bind(this))
            .on('click', '.ProjectSessionsList-filterCancel', this.onCancelFilteringClick.bind(this))
            .on('click', '.ProjectSessionsList-group', this.onGroupingClick.bind(this))
            .on('click', '.ProjectSessionsList-filter', this.onFilteringClick.bind(this))
            .on('click', '.ProjectSessionsList-heading', this.onHeadingClick.bind(this))
            .on('click', '.ProjectSessionsList-row-body', this.onRowBodyClick.bind(this))
            .on('click', '.single', this.onSingleHeaderRowClick.bind(this))
            .on('click', '.ProjectSessionsList-row-headerArrow', this.onHeaderArrowClick.bind(this))
            .on('change', 'input[name="ProjectSessionsListCheckbox"]', this.onSelectAllCheckboxChange.bind(this))
            .on('change', 'input[name="ProjectSessionsListRowCheckbox"]', this.onRowCheckboxChange.bind(this))
            .on('change', 'input[name="ProjectSessionsListItemCheckbox"]', this.onItemCheckboxChange.bind(this))
            .on('click', '#deleteButton', this.onDeleteClick.bind(this))
            .on('click', '#editFieldsButton', this.onBulkEditClick.bind(this))
            .on('click', '#emailButton', this.onEmailClick.bind(this))
            .on('change', '.form-control', this.onGroupingChange.bind(this))
            .on('change', '#mail-selector', this.ChangeEmail.bind(this))
            .on('click', '.ProjectSessionsList-itemCheckbox', (event) => {
                event.stopPropagation();
            });
        $(window).on('load resize scroll', this.sizeChart.bind(this));

    }

    /* This could be tweaked further so that it makes the chart only as wide
       as the viewport if smaller than the page width. */
    sizeChart() {
        //var maxHeight = '';
        /* First, make the wrapper no wider than the page. Start by hiding the
         chart so it can't push out the page width, but fix the height of the
         wrapper temporarily so things don't bounce around. Then measure the
         width of the wrapper without the chart, which should fill the width of
         its container. Set this value as the wrapper's max-width, then show
         the chart again and unset the wrapper height. Only show the scrollbars
         if necssary. */
        this.chartWrapper.css({
            height: this.chartWrapper.height(),
            maxWidth: ''
        });
        this.chartTable.hide();
        var maxWidth = this.chartWrapper.width();
        this.chartWrapper.css({
            maxWidth: maxWidth,
            height: '',
            overflowX: this.chartWidth > maxWidth ? 'auto' : 'visible'
        });
        this.chartTable.show();

        if (!this.chartTable.find('td:first')[0]) {
            var noResultsText = 'No results';
            var colspan = COLUMN_NAMES.length;
            this.chartTable.append('\
                <tr>\
                    <td></td>\
                    <td></td>\
                    <td colspan="' + colspan + '">\
                        <em>' + noResultsText + '</em>\
                    </td>\
                </tr>\
            ');
            return;
        }

        /* Next, make the wrapper no taller than the viewport. Do this by measuring
         the height of the viewport, then subtracting the offset of the first row
         (not header, which we assume is sticky). Only show the scrollbars if
         necessary. */
        var viewportHeight = $(window).height(), //same as document.documentElement.clientHeight
            firstRowOffset = this.chartTable.find('td:first')[0].getBoundingClientRect().top || 0;
        //if (firstRowOffset > viewportHeight) {
        //    // chart is off the bottom of the screen, so no need for maxHeight
        //    maxHeight = '';
        //} else if (firstRowOffset < 0) {
        //    // top of chart is off the top of the screen, so make the chart the same
        //    // height as the viewport
        //    maxHeight = viewportHeight;
        //} else {
        //    // chart is on screen, so make it fit entirely within the viewport, but no
        //    // less than 150px high
        //    maxHeight = Math.max(viewportHeight - firstRowOffset, 150);
        //}

        //this.chartWrapper.css({
        //    maxHeight: maxHeight,
        //    overflowY: this.chartHeight > maxHeight ? 'auto' : 'visible'
        //});
    }


    setGroupByColumn(groupingColumn) {
        this.groupByColumn = groupingColumn;
    }

    setFilterColumns(filterColumns) {
        this.filterColumns = filterColumns;

        let cloneFilterColumns = [...this.filterColumns];

        if (cloneFilterColumns.length > 0 && !cloneFilterColumns.includes(this.groupByColumn)) {
            cloneFilterColumns.push(this.groupByColumn);
        }

        if (cloneFilterColumns.length > 0) {
            $('.ProjectSessionsList-heading, .ProjectSessionsList-column, .ProjectSessionsList-groupOption').addClass('d-none');
            for (let filterColumn of cloneFilterColumns) {
                $(`.ProjectSessionsList-heading[data-column="${filterColumn}"], .ProjectSessionsList-column[data-column="${filterColumn}"], .ProjectSessionsList-groupOption[data-column="${filterColumn}"]`).removeClass('d-none');
            }
        } else {
            $('.ProjectSessionsList-heading, .ProjectSessionsList-column').removeClass('d-none');
        }
    }

    updateHtmlForSortByColumn(sortByColumn, direction) {
        this.sortByColumn = sortByColumn;
        this.sortDirection = direction;
        let directionIcon = this.sortDirection === SortDirection.ASCENDING ? 'asc' : 'desc';
        let state = "active";
        var colNumber = parseInt($(`.ProjectSessionsList-heading[data-column="${this.sortByColumn}"]`).prop('cellIndex') + 1);

        $(`.ProjectSessionsList-heading`).css({ fontWeight: 'normal' });
        //$(`.ProjectSessionsList-heading[data-column="${this.sortByColumn}"]`).css({ fontWeight: 'bold' });

        $(`.ProjectSessionsList-heading`).attr('data-direction', null);
        $(`.ProjectSessionsList-heading[data-column="${this.sortByColumn}"]`).attr('data-direction', this.sortDirection);

        $(`.ProjectSessionsList-heading`).removeClass(state);
        $(`.ProjectSessionsList-heading`).removeClass('asc desc');
        $(`.ProjectSessionsList-heading[data-column="${this.sortByColumn}"]`).addClass(state);
        $(`.ProjectSessionsList-heading[data-column="${this.sortByColumn}"]`).addClass(directionIcon);

        //$(`.ProjectSessionsList-heading[data-column="${this.sortByColumn}"] i`).attr('class', directionIcon);
        //$(`.ProjectSessionsList-heading[data-column="${this.sortByColumn}"]`)
        //.addClass();

        //Activate column
        $(".ProjectSessionsList-items td").removeClass(state);
        $(".ProjectSessionsList-items td:nth-child(" + colNumber + ")").addClass(state);
    }

    loadData() {
        PROJECTID = this.data.parentid;
        $.ajax({
            url: LOAD_DATA_API_URL.replace('{parentId}', this.data.parentid),
            success: (data) => {
                this.resultData = data.map((item) => {
                    item['expireAt'] = normalizeDate(item['expireAt']);
                    return item;
                });
                this.updateList(this.resultData, this.groupByColumn);
                this.afterUpdateActions();
                this.sortByColumn == this.groupByColumn;  //we sort also by groupcolumn.  Maybe it is better to leave the sortcolumn unchenged if it is already filled?
                this.sortData(this.sortByColumn, this.sortDirection);

                //group the item rows by the wanted groupcolumn, sort the groups and create the html.      
                this.updateList(this.resultData, this.groupByColumn);
                this.afterUpdateActions();
                this.updateHtmlForSortByColumn(this.sortByColumn, this.sortDirection);

            },
            error: (e) => { console.warn(e); }
        });
    }

    filterData(search) {
        if (search === '') {
            this.updateList(this.resultData, this.groupByColumn);
            return;
        }

        let filteredData = { ...this.resultData };

        filteredData = Object.values(filteredData).filter(item => {
            let loopObject = this.filterColumns.length > 0
                ? this.filterColumns
                : COLUMNS;

            for (let i in loopObject) {
                let column = loopObject[i];

                if (typeof item[column] === 'string') {
                    if (item[column].toLowerCase().indexOf(search.toLowerCase()) !== -1) {
                        return true;
                    }
                }
            }
            return false;
        });

        this.updateList(filteredData, this.groupByColumn);
        //this.expandAll();

    }

    sortData(sortByColumn, sortDirection) {
        let sortedData = { ...this.resultData };  //resultdata is the array of all itemrows (so not the grouprows) sorted like they were sorted before the click on the header.

        let compare = (sortDirection === SortDirection.ASCENDING)
            ? (a, b) => (a[sortByColumn] > b[sortByColumn]) ? 1 : ((b[sortByColumn] > a[sortByColumn]) ? -1 : 0)
            : (a, b) => (a[sortByColumn] < b[sortByColumn]) ? 1 : ((b[sortByColumn] < a[sortByColumn]) ? -1 : 0);

        //Sort the item rows by the wanted column in the wanted direction
        sortedData = Object.values(sortedData).sort(compare);

        this.resultData = sortedData;

    }


    makegroupedcolumnleftcolumn(groupByColumn) {

        for (let i in COLUMNS) { COLUMNS[i] = COLUMNS_D[i]; }
        for (let i in COLUMN_NAMES) { COLUMN_NAMES[i] = COLUMN_NAMES_D[i]; }
        for (let i in VALUES) { VALUES[i] = VALUES_D[i]; }

        var groupByColumnIndex = 0;
        var groupByColumnName = "";
        for (let x in COLUMNS) {
            if (COLUMNS[x] == groupByColumn) {
                groupByColumnIndex = x;
                groupByColumnName = COLUMN_NAMES[x];
            }
        }
        for (var y = groupByColumnIndex; y >= 1; y--) {
            COLUMNS[y] = COLUMNS[y - 1];
            COLUMN_NAMES[y] = COLUMN_NAMES[y - 1]
            VALUES[y] = VALUES[y - 1];
        }
        COLUMNS[0] = groupByColumn;
        COLUMN_NAMES[0] = groupByColumnName;
        VALUES[0] = groupByColumn;
    }



    loadHeadings() {
        $('.ProjectSessionsList-headings').html(this.getHeadingsHtml());
    }



    sortTheGroups(sortedArray) {

        //define the sorting functions
        function compareAscending(a, b) {
            if (a.sortColumnValue.toString().toLowerCase() < b.sortColumnValue.toString().toLowerCase()) { return 1; }
            if (a.sortColumnValue.toString().toLowerCase() > b.sortColumnValue.toString().toLowerCase()) { return -1; }
            return 0;
        }
        function compareDescending(a, b) {
            if (a.sortColumnValue.toString().toLowerCase() < b.sortColumnValue.toString().toLowerCase()) { return -1; }
            if (a.sortColumnValue.toString().toLowerCase() > b.sortColumnValue.toString().toLowerCase()) { return 1; }
            return 0;
        }
        function compareAscendingNumerical(a, b) {
            if (a.sortColumnValue < b.sortColumnValue) { return 1; }
            if (a.sortColumnValue > b.sortColumnValue) { return -1; }
            return 0;
        }
        function compareDescendingNumerical(a, b) {
            if (a.sortColumnValue < b.sortColumnValue) { return -1; }
            if (a.sortColumnValue > b.sortColumnValue) { return 1; }
            return 0;
        }

        //the sortcolumnvalues can be strings, numbers or a combination of strings and numbers.
        //check if one or more sortColumnValues are strings
        var areThereAnyStrings = sortedArray.some(function (item) {
            return isNaN(item.sortColumnValue);
        });

        //use the sorting functions
        if (this.sortDirection == SortDirection.ASCENDING) {
            if (areThereAnyStrings) {
                sortedArray.sort(compareAscending);
            } else {
                sortedArray.sort(compareAscendingNumerical);
            }
        } else {
            if (areThereAnyStrings) {
                sortedArray.sort(compareDescending);
            } else {
                sortedArray.sort(compareDescendingNumerical);
            }
        }
        //return sortedArray
    }


    updateList(data, groupByColumn) {
        //this method updates the whole sessions table. It creates the html for the grouprows and the itemrows and also sorts the groups

        for (var j in data) {
            var row = data[j];
            if (row.isExpired == true) {
                row.status = "Expired"; //if session is expired then overrule the status by making it "Expired" (this is needed for grouping on expired status) 
            }
        }
        const groupedData = groupBy(data, groupByColumn);
        this.makegroupedcolumnleftcolumn(groupByColumn);

        this.loadHeadings();
        let groupId = 0;
        let sortedArray = [];

        //loop through each "grouped row" (a grouped row can be a item row or it can be a 'header row' with one ore more hidden item rows underneath that headerrow)
        for (var groupKey in groupedData) {

            var htmlForOneGroup = "";
            var valueForSortColumnInThisGroup = "";
            let group = groupedData[groupKey];

            if (group.length > 1) {
                //There are multiple item rows in this group. so we must create one "group" row and all item rows must be created as hidden rows underneath that row (die kunnen uitgeklapt worden)
                let values = [];
                for (let j in COLUMNS) {
                    var columnValue = this.getValueForColumnInGroupedRow(COLUMNS[j], group);
                    values.push(columnValue);
                    if (COLUMNS[j] == this.sortByColumn) {
                        valueForSortColumnInThisGroup = columnValue;
                        valueForSortColumnInThisGroup = this.UpdateValueForSortingTheGroups(this.sortByColumn, valueForSortColumnInThisGroup, group);
                    }//if his is the sorted column, then save the value also in an array that will be used later for sortng the groups
                }
                var groupedRowHtml = this.getHtmlForGroupedRow(groupId, values);
                htmlForOneGroup = groupedRowHtml;
                //now loop through the items in the group to get the html for each item row (de rijen die open en dicht geklapt kunnen worden)
                group.forEach((item) => {
                    let itemValues = [];
                    for (let j in VALUES + 1) {
                        let column = VALUES[j];
                        itemValues.push(item[column]);
                    }
                    var itemRowHtml = this.getHtmlForItemRow(groupId, item['id'], itemValues, true); //hidden=true
                    htmlForOneGroup = htmlForOneGroup + itemRowHtml;
                });
            } else {
                //there is only one item row in this group, so there is no group row end the item row must be visible. 
                group.forEach((item) => {
                    let itemValues = [];
                    for (let j in VALUES + 1) {
                        let column = VALUES[j];
                        itemValues.push(item[column]);
                        if (column == this.sortByColumn) {
                            valueForSortColumnInThisGroup = item[column];
                            valueForSortColumnInThisGroup = this.UpdateValueForSortingTheGroups(this.sortByColumn, valueForSortColumnInThisGroup, group);
                        }
                    }
                    htmlForOneGroup = this.getHtmlForItemRow(groupId, item['id'], itemValues, false); //hidden=false
                });
            }

            //ad the results to the (to be) sortedarray
            sortedArray.push({
                sortColumnValue: valueForSortColumnInThisGroup,
                groupHtml: htmlForOneGroup
            });

            groupId++;
        }

        this.sortTheGroups(sortedArray);
        //now the sortedArray itself is sorted by the "group" rows
        //And the itemrows within each group are also sorted.
        let sesionTableHtml = '';
        for (let g in sortedArray) {
            sesionTableHtml += sortedArray[g].groupHtml;
        }
        $('.ProjectSessionsList-items').html(sesionTableHtml);

        resetButtonsAfterCheckboxChange();
    }







    getValueForColumnInGroupedRow(column, group) {


        switch (column) {
            case 'participantName':
                var plural = group.length > 1 ? "s" : "";
                const participants = group.map(x => x.participantName);
                const uniqueParticipants = participants.filter(function (value, index, self) {
                    return self.indexOf(value) === index;
                });
                if (uniqueParticipants.length > 1) {
                    return `${uniqueParticipants.length} participant` + plural + ` (${group.length} questionaire` + plural + ')'
                }
                else {
                    return uniqueParticipants;
                }


                break;

            case 'subject':
                var plural = group.length > 1 ? "s" : "";
                const subjects = group.map(x => x.subject);
                const uniqueSubjects = subjects.filter(function (value, index, self) {
                    return self.indexOf(value) === index;
                });

                if (uniqueSubjects.length > 1) {
                    return `${uniqueSubjects.length} subject` + plural;
                }
                else {
                    return uniqueSubjects;
                }

                break;

            case 'instrumentAlias':
                const instrumentAliasses = group.map(x => x.instrumentAlias);
                const uniqueInstruments = instrumentAliasses.filter(function (value, index, self) {
                    return self.indexOf(value) === index;
                });
                var plural = uniqueInstruments.length > 1 ? "s" : "";
                if (uniqueInstruments.length > 1) {
                    return `${uniqueInstruments.length} instrument` + plural;
                }
                else {
                    return uniqueInstruments[0];
                }

                break;

            case 'expireAt':
                let allCompleted = true;
                let expiryDates = [];
                for (let item of group) {

                    if (!item['done']) {
                        allCompleted = false;
                        expiryDates.push(item[column]);
                    }
                }
                let sortedExpiryDates = Object.values(expiryDates).sort(
                    (a, b) => (a < b) ? 1 : ((b < a) ? -1 : 0));

                return allCompleted ? 'Completed' : formatExpiryDate(sortedExpiryDates[0]);
                break;

            case 'completed1':
                let countCompleted = 0;
                for (let item of group) {
                    if (item['completed1'] == 1) {
                        countCompleted++;
                    }
                }
                return `${countCompleted}/${group.length}`;
                break;

            case 'progress':
                let countCompletedPercentage = 0;
                for (let item of group) {

                    if (isNumber(item['progress']) && item['progress']) {
                        countCompletedPercentage += (item['progress'] / 100);
                    }
                    else {
                        countCompletedPercentage += 0;
                    }

                }
                const percentage = Math.round(((countCompletedPercentage / group.length)) * 100);
                const progressBar = `<div class="progress progress-bar-custom">
                                <div class="progress-bar " role="progressbar" style="width: ${percentage}%; height: 16px; background-color:#9f9f9f;" aria-valuenow="${percentage}" aria-valuemin="0" aria-valuemax="100"><strong class="ml-10 mr-10">${percentage}%</strong></div>
                            </div>
                           `;
                return `${progressBar}`;
                break;

            case 'status':

                let status = '';
                let icon = '';
                let created = 0;
                let pending = 0;
                let started = 0;
                let finished = 0;
                let completed = 0;
                let expired = 0;
                for (let item of group) {

                    //if (item['isExpired'] == true) {
                    //    expired++;
                    //} else {
                    switch (item[column]) {
                        case "Created":
                            created++;
                            break;
                        case "SentToParticipant":
                            pending++;
                            break;
                        case "ParticipantStarted":
                            started++;
                            break;
                        case "ParticipantFinishedAnswers":
                            finished++;
                            break;
                        case "CompletelyFinished":
                            completed++;
                            break;
                        case "Expired":
                            expired++;
                            break;
                    }
                    //}                   
                }

                var statusCounter = 0;
                if (created >= 1) {
                    statusCounter++;
                    status = ' Created';
                    icon = icon = '<img src=/assets/img/icon/icon-plus.svg alt="created mark icon" style="width:16px; height:16px;">';
                }
                if (pending >= 1) {
                    statusCounter++;
                    status = ' Pending';
                    icon = '<img src=/assets/img/icon/icon-envelop.svg alt="envelope mark icon" style="width:16px; height:16px;">';
                }
                if (started >= 1) {
                    statusCounter++;
                    status = ' Started';
                    icon = '<img src=/assets/img/icon/icon-check2.svg alt="in progress mark icon" style="width:16px; height:16px;">';
                }
                if (finished >= 1) {
                    statusCounter++;
                    status = ' Finished';
                }
                if (completed >= 1) {
                    statusCounter++;
                    status = ' Completed';
                    icon = '<img src=/assets/img/icon/icon-check.svg alt="check mark icon" style="width:16px; height:16px;">';
                }
                if (expired >= 1) {
                    statusCounter++;
                    status = ' Expired';
                    icon = '<img src=/assets/img/icon/icon-close.svg alt="expired icon" style="width:16px; height:16px;">';
                }

                if (statusCounter == 1) {
                    status = `${icon}` + ` ` + group.length + status;
                } else {
                    status = statusCounter + " statuses";
                }

                return `${status}`;

                break;
            case 'type':
                let typeValue = "";
                const imageTypes = group.map(x => x.type);
                const uniqueTypes = imageTypes.filter(function (value, index, self) {
                    return self.indexOf(value) === index;
                });
                var plural = uniqueTypes.length > 1 ? "s" : "";
                if (uniqueTypes.length > 1) {
                    return `${uniqueTypes.length} type` + plural;
                }
                else {
                    if (uniqueTypes == 1) {
                        typeValue = 'Self Image';
                    }
                    else if (uniqueTypes == 2) {
                        typeValue = 'Feedback Image';
                    }
                    return typeValue;
                }
                break;

            case 'lastMailSentType':
                let mailValue = "";
                const mailTypes = group.map(x => x.lastMailSendType).filter(x => x != "None");
                const uniquemailTypes = mailTypes.filter(function (value, index, self) {
                    return self.indexOf(value) === index;
                });
                var plural = uniquemailTypes.length > 1 ? "s" : "";
                if (uniquemailTypes.length > 1) {
                    return `${uniquemailTypes.length} email type` + plural;
                }
                else {
                    mailValue = uniquemailTypes;
                }
                return mailValue;
                break;

            case 'lastMailSentDate':
                let mailDateTypes = group.map(x => normalizeDate(x.lastMailSendText).toString());
                let uniquemailDateTypes = mailDateTypes.filter(function (value, index, self) {
                    return value != 'Invalid Date' && self.indexOf(value) === index;
                });
                if (uniquemailDateTypes.length) {
                    return uniquemailDateTypes.length > 1 ? `${uniquemailDateTypes.length} dates` : this.formatDate(uniquemailDateTypes[0])
                }
                else {
                    return "";
                }
                break;

            //?? - TODO - dit deel geeft JS Errors v.w. duplicaten - wellicht bij de merge niet goed geggaan

            //    break;
            //case 'type':
            //    let typeValue = "";
            //    const imageTypes = group.map(x => x.type);
            //    const uniqueTypes = imageTypes.filter(function (value, index, self) {
            //        return self.indexOf(value) === index;
            //    });
            //    var plural = uniqueTypes.length > 1 ? "s" : "";
            //    if (uniqueTypes.length > 1) {
            //        values.push(`${uniqueTypes.length} type` + plural);
            //    }
            //    else {
            //        if (uniqueTypes == 1) {
            //            typeValue = 'Self Image';
            //        }
            //        else if (uniqueTypes == 2) {
            //            typeValue = 'Feedback Image';
            //        }
            //        else if (uniqueTypes == 4) {
            //            typeValue = 'Current Environment';
            //        }
            //        else if (uniqueTypes == 5) {
            //            typeValue = 'Desired Environment';
            //        }
            //        else if (uniqueTypes == 6) {
            //            typeValue = 'Current Feedback Image';
            //        }
            //        else if (uniqueTypes == 7) {
            //            typeValue = 'Desired Feedback Image';
            //        }
            //        values.push(typeValue);
            //    }
            //    break;

        }

    }


    UpdateValueForSortingTheGroups(column, value, group) {

        if (column == 'progress') {
            let countCompletedPercentage = 0;
            for (let item of group) {
                if (isNumber(item['progress']) && item['progress']) {
                    countCompletedPercentage += (item['progress'] / 100);
                }
            }
            let returnValue = Math.round(((countCompletedPercentage / group.length)) * 100);
            return returnValue;
        }

        if (column == 'type') {
            if (value == 1) {
                return 'Self Image';
            }
            else if (value == 2) {
                return 'Feedback Image';
            }
            else if (value == 4) {
                return 'Current Environment';
            }
            else if (value == 5) {
                return 'Desired Environment';
            }
            else if (value == 6) {
                return 'Current Feedback Image';
            }
            else if (value == 7) {
                return 'Desired Feedback Image';
            }
        }

        if (column == "status") {
            return value;

        }


        //if (column == "expireAt") { //datums komen in twee verschillende formats die ik niet kan omzetten           
        //    var test2 = value.toString();
        //    var returnDate = new Date(value);
        //    return returnDate;
        //}



        //for all other columns:
        return value;


        //switch (column) {
        //    case 'expireAt':
        //        return value;
        //    case 'completed1'://this is the column with header  "progress"  
        //        return value;
        //    case 'progress':
        //        let countCompletedPercentage = 0;
        //        for (let item of group) {
        //            if (isNumber(item['progress']) && item['progress']) {
        //                countCompletedPercentage += (item['progress'] / 100);
        //            }
        //        }
        //        let value = Math.round(((countCompletedPercentage / group.length)) * 100);
        //        return value;
        //    case 'status':
        //        return value;
        //    case 'type':
        //        if (value == 1) {
        //            return 'Self Image';
        //        }
        //        else if (value == 2) {
        //            return 'Feedback Image';
        //        }
        //        else if (value == 4) {
        //            return 'Current Environment';
        //        }
        //        else if (value == 5) {
        //            return 'Desired Environment';
        //        }
        //        else if (value == 6) {
        //            return 'Current Feedback Image';
        //        }
        //        else if (value == 7) {
        //            return 'Desired Feedback Image';
        //        }
        //        break;
        //    case 'lastMailSentType':
        //        return value;
        //    case 'lastMailSentDate':
        //        return value;
        //    default:
        //        return value;
        //}

    }




    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    afterUpdateActions() {
        this.updateFilterOptions();
        this.setFilterColumns(this.filterColumns);
        this.setGroupByColumn(this.groupByColumn);
    }

    deleteItems(items, callback = () => { }) {
        $.ajax({
            url: DELETE_API_URL.replace('{parentId}', this.data.parentid),
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(items),
            success: (data) => {
                const attributeSelectors = items.map((id) => {
                    return `.ProjectSessionsList-row-body[data-id="${id}"]`;
                });

                $(attributeSelectors.join(',')).hide(200);

                callback();

                //remove from memory:
                this.resultData = this.resultData.filter((item) => {
                    return !items.includes(item.id);
                });
                window.location.reload();

                //TODO: NICE TO HAVE: cleanup groupheaders when after deletion a groupheader has no items anymore
            },
            error: (e) => {
                console.warn(e);
            }
        });
    }


    editItems(items, fieldvalues, callback = () => { }) {
        $.ajax({
            url: EDIT_API_URL.replace('{parentId}', this.data.parentid),
            type: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify({
                sessions: items,
                fieldValues: fieldvalues
            }),
            success: (data) => {
                const attributeSelectors = items.map((id) => {
                    return `.ProjectSessionsList-row-body[data-id="${id}"]`;
                });

                $(attributeSelectors.join(',')).hide(200);

                callback();

                //remove from memory:
                this.resultData = this.resultData.filter((item) => {
                    return !items.includes(item.id);
                });
                window.location.reload();

                //TODO: NICE TO HAVE: cleanup groupheaders when after deletion a groupheader has no items anymore
            },
            error: (e) => {
                console.warn(e);
            }
        });
    }

    updateFilterOptions() {
        $('.ProjectSessionsList-filterOptions').html(this.getFilterOptionsHtml());
    }

    expandRow(groupId) {
        $(`.ProjectSessionsList-row-header[data-groupid="${groupId}"] .ProjectSessionsList-row-headerArrow`)
            .removeClass('ti-angle-down')
            .addClass('ti-angle-up');

        $(`.ProjectSessionsList-row-body[data-groupid="${groupId}"]`)
            .removeClass('d-none');
    }

    groupRow(groupId) {
        $(`.ProjectSessionsList-row-header[data-groupid="${groupId}"] .ProjectSessionsList-row-headerArrow`)
            .removeClass('ti-angle-up')
            .addClass('ti-angle-down');

        $(`.ProjectSessionsList-row-body[data-groupid="${groupId}"]`)
            .addClass('d-none');
    }

    expandAll() {
        $('.ProjectSessionsList-row-headerArrow')
            .removeClass('ti-angle-down')
            .addClass('ti-angle-up');
        $('.ProjectSessionsList-row-body')
            .removeClass('d-none');
    }

    groupAll() {
        $('.ProjectSessionsList-row-headerArrow')
            .removeClass('ti-angle-up')
            .addClass('ti-angle-down');
        $('.ProjectSessionsList-row-body')
            .addClass('d-none');
    }

    getHeadingsHtml() {
        let columnsHtml = '';

        for (let i in COLUMNS) {
            let column = COLUMNS[i];
            let columnName = COLUMN_NAMES[i];

            //let sortIcon = SORTING_COLUMNS.includes(column) ? ' <i class="fa fa-sort"></i>' : '';
            //let sortOrder = this.sortDirection === SortDirection.ASCENDING ? 'desc' : 'asc'
            if (i == 0) {
                columnsHtml += `<th class="ProjectSessionsList-heading cursor-pointer text-nowrap static-col" data-column="${column}">${columnName}</th>`;
            }
            else {
                columnsHtml += `<th class="ProjectSessionsList-heading cursor-pointer text-nowrap" data-column="${column}">${columnName}</th>`;
            }
        }

        return `
            <th class="static-col">
                <div class="ProjectSessionsList-itemCheckbox custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input row-selector" name="ProjectSessionsListCheckbox">
                    <label class="custom-control-label"></label>
                </div>
            </th>
            <th class="static-col">&nbsp;</th>
            ${columnsHtml}
        `;
    }

    updateHeadingsColor() {
        let columns = document.getElementsByClassName('ProjectSessionsList-heading');
        let group = this.groupByColumn;
        Array.prototype.forEach.call(columns, function (i) {
            if (i.dataset.column === group) {
                i.classList.add("active");
                i.classList.add("asc");
            }
            else {
                i.classList.remove("active");
            };
        })
    }

    getHtmlForGroupedRow(id, values) {
        let columnsHtml = '';

        for (let i in COLUMNS) {
            let column = COLUMNS[i];
            let value = values[i];
            if (i == 0) {
                columnsHtml += `<td class="ProjectSessionsList-column static-col" data-column="${column}">${value}</td>`;
            } else {
                columnsHtml += `<td class="ProjectSessionsList-column" data-column="${column}">${value}</td>`;
            }
        }

        return `
            <tr class="ProjectSessionsList-row-header" data-groupid="${id}">
              <td width="1" class="static-col"><div class="ProjectSessionsList-rowCheckbox custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input row-selector" name="ProjectSessionsListRowCheckbox" value="${id}">
                  <label class="custom-control-label"></label>
              </div></td>
              <td width="1" class="static-col"><i class="ProjectSessionsList-row-headerArrow ti-angle-down cursor-pointer"></i></td>
              ${columnsHtml}
          </tr>
        `;
    }


    getHtmlForItemRow(id, itemId, itemValues, hidden = true) {

        let hiddenClass = 'ProjectSessionsList-row-body d-none';  //item rows must be hidden by default
        if (hidden == false) {
            //if the item row is not hidden, then there is only one item in the group. So that item row must be shown. 
            //The row must be styled like a header row, but it must be clickable like an item row.
            hiddenClass = 'ProjectSessionsList-row-header single';
        }

        let columnsHtml = '';

        for (let i in COLUMNS) {
            let column = COLUMNS[i];
            let value = itemValues[i];
            let extraStyle = '';

            switch (column) {
                case 'expireAt':
                    value = formatExpiryDate(value);
                    break;
                case 'completed1'://this is the column with header  "progress"  
                    if (itemValues[i] == 1) { value = '1/1'; }
                    else { value = '0/1'; }
                    break;
                case 'progress':
                    if (isNumber(itemValues[i])) {
                        //value = Math.round(itemValues[i - 1]) + ' %';
                        //extraStyle = 'number-center';
                        var percentage = Math.round(itemValues[i]);
                        //extraStyle = 'number-center';
                        value = `<div class="progress progress-bar-custom">
                                <div class="progress-bar" role="progressbar" style="width: ${percentage}%; height: 16px; background-color:#9f9f9f;" aria-valuenow="${percentage}" aria-valuemin="0" aria-valuemax="100"><strong class="ml-10 mr-10">${percentage}%</strong></div>
                            </div>
                           `;
                    }
                    else {
                        value = '';
                    }


                    break;

                case 'status':
                    let statusColor = ""
                    let icon = ""
                    switch (value) {

                        case "ParticipantStarted":
                            value = "In Progress";
                            icon = '<img src=/assets/img/icon/icon-check2.svg alt="in progress mark icon" style="width:16px; height:16px;">';
                            statusColor = "warning";
                            break;
                        case "ParticipantFinishedAnswers":
                            statusColor = "info";
                            break;
                        case "SentToParticipant":
                            value = "Pending";
                            icon = '<img src=/assets/img/icon/icon-envelop.svg alt="envelope mark icon" style="width:16px; height:16px;">';
                            statusColor = "default";
                            break;
                        case "CompletelyFinished":
                            value = "Complete";
                            icon = '<img src=/assets/img/icon/icon-check.svg alt="check mark icon" style="width:16px; height:16px;">';
                            statusColor = "success";
                            break;

                        case "Created":
                            value = "Created";
                            icon = icon = '<img src=/assets/img/icon/icon-plus.svg alt="created mark icon" style="width:16px; height:16px;">';
                            break;
                    }
                    if (itemValues[10] === true) {
                        value = "Expired";
                        icon = '<img src=/assets/img/icon/icon-close.svg alt="expired icon" style="width:16px; height:16px;">';
                    }
                    //value = `<span class="badge badge-${statusColor}">${value.replace(/([A-Z][a-z])/g, ' $1').replace(/(\d)/g, ' $1').toLowerCase()}</span>`;
                    value = `<span >${icon} ${value.replace(/([A-Z][a-z])/g, ' $1').replace(/(\d)/g, ' $1')}</span>`;

                    break;

                case 'type':
                    if (value == 1) {
                        value = 'Self Image';
                    }
                    else if (value == 2) {
                        value = 'Feedback Image';
                    }
                    else if (value == 4) {
                        value = 'Current Environment';
                    }
                    else if (value == 5) {
                        value = 'Desired Environment';
                    }
                    else if (value == 6) {
                        value = 'Current Feedback Image';
                    }
                    else if (value == 7) {
                        value = 'Desired Feedback Image';
                    }
                    break;
                case 'lastMailSentType':
                    if (value != "None") {
                        value = `<span class="badge badge-default">${value}</span>`;
                    }
                    else {
                        value = '';
                    }
                    break;
                case 'lastMailSentDate':
                    if (itemValues[9] == undefined) {
                        value = '';
                    } else {
                        var dateValue = new Date(itemValues[9]);
                        value = this.formatDate(dateValue);
                    }
                    break;
            }

            if (i == 0) {
                columnsHtml += `<td class="ProjectSessionsList-column static-col" data-column="${column}">${value}</td>`;
            } else {
                columnsHtml += `<td class="ProjectSessionsList-column" data-column="${column}">${value}</td>`;
            }
        }

        return `<tr class="cursor-pointer ${hiddenClass}" triggerDetailPanel data-groupid="${id}" data-id="${itemId}">
                  <td width="1" class="static-col">
                    <div class="ProjectSessionsList-itemCheckbox custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input row-selector" name="ProjectSessionsListItemCheckbox" value="${itemId}" data-groupid="${id}">
                        <label class="custom-control-label"></label>
                    </div>
                  </td>
                  <td width="1" class="static-col">&nbsp;</td>
                  ${columnsHtml}
              </tr>`;
    }










    getFilterOptionsHtml() {
        let html = '';
        var checkedCount = 0;
        for (let i in FILTER_COLUMNS) {
            let filterColumn = FILTER_COLUMNS[i];
            let filterColumnName = FILTER_COLUMN_NAMES[i];
            if (this.filterColumns.includes(filterColumn)) checkedCount++;

            let checked = checkedCount > 0 ? this.filterColumns.includes(filterColumn) ? 'checked' : '' : 'checked';

            html += `
            <div class="custom-control custom-checkbox">
                <input id="fi-${filterColumn}" class="custom-control-input" type="checkbox" name="filter" value="${filterColumn}" ${checked}>
                <label class="custom-control-label" for="fi-${filterColumn}">${filterColumnName}</label>
            </div>`;
        }

        return html;
    }

    getMailOptionsHtml(data) {
        let html = '';
        let action = EMAIL_SEND_API_URL.replace('{parentId}', this.data.parentid);

        for (let session in sessions) {
            html += `<input type="hidden" name="sessions" value=${sessions[session]} />`;
        }

        html += '<select name="templateAlias" data-provide="selectpicker">';

        for (let i in data.templates) {
            html += `<option value="${data.templates[i]}">${data.templates[i]}</option>`;
        }

        html += '</select>';
        html += `</form></p>`;

        return html;
    }

    onAddClick(event) {
        event.preventDefault();

        window.location.href = NEW_URL.replace('{parentId}', this.data.parentid);
    }

    onDeleteClick() {
        var self = this;
        event.preventDefault();

        let values = [];
        $('input[name="ProjectSessionsListItemCheckbox"]:checked').each((i, elm) => {
            values.push($(elm).val());
        });

        this.currentSelectedValues = values;

        app.modaler({
            html: `You have selected ${values.length} questionnaire(s) for deletion.`,
            type: 'center',
            title: `Delete questionnaires`,
            size: 'lg',
            onConfirm: function () {
                self.onDeleteItemsConfirm();
            },
            cancelVisible: true,
            confirmVisible: true,
            confirmText: 'Delete'
        });
    }

    onBulkEditClick() {
        var self = this;

        event.preventDefault();

        let values = [];
        $('input[name="ProjectSessionsListItemCheckbox"]:checked').each((i, elm) => {
            values.push($(elm).val());
        });

        this.currentSelectedValues = values;


        var html = `
        <dl class="bulk-edit-fields">
            <dd>
                <div class=""><label>Expire on date (at 0:00)</label></div>
                <div class="input-group date" data-provide="datepicker">
                    <input type="text" class="form-control" id="expireat">
                    <div class="input-group-addon">
                        <span class="glyphicon glyphicon-th"></span>
                    </div>
                </div>
            </dd>
        </dl>
        `;


        var mod = app.modaler({
            html: html,
            type: 'center',
            title: `Edit fields`,
            size: 'lg',
            onConfirm: function () {

                var bulkEditFields = document.querySelector('.bulk-edit-fields');
                var expireatField = bulkEditFields.querySelector('#expireat');

                if (expireatField.value === '') {
                    return false;
                }

                var fieldValues = {};
                fieldValues['expireat'] = expireatField.value;

                self.onBulkEditItemsConfirm(fieldValues);
            },
            cancelVisible: true,
            confirmVisible: true,
            confirmText: 'Save'
        });

    }

    onEmailClick() {
        event.preventDefault();

        let values = [];
        $('input[name="ProjectSessionsListItemCheckbox"]:checked').each((i, elm) => {
            values.push($(elm).val());
        });
        sessionIDs = values;
        $('#subjectids').attr('data-emails', values);
        $.ajax({
            url: EMAIL_API_URL.replace('{parentId}', this.data.parentid),
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(values),
            //success: this.showMailDialog.bind(this)
            success: updateEmailList.bind(this)
        });

    }

    onDeleteItemsConfirm() {
        this.deleteItems(this.currentSelectedValues);
    }

    onBulkEditItemsConfirm(fieldValues) {
        this.editItems(this.currentSelectedValues, fieldValues);
    }
    

    onSelectAllCheckboxChange(event) {
        $(`.ProjectSessionsList-itemCheckbox input`).each((i, elm) => {
            let $elm = $(elm);
            $elm.prop('checked', event.target.checked);
        });

        $(`input[name="ProjectSessionsListRowCheckbox"]`).each((i, elm) => {
            let $elm = $(elm);
            $elm.prop('checked', event.target.checked);
            elm.indeterminate = false;
        });

        $(`.ProjectSessionsList-items td`).each((i, elm) => {
            let $elm = $(elm);
            event.target.checked ? $elm.addClass('activerow') : $elm.removeClass('activerow')
        });

        let elm = $('input[name="ProjectSessionsListCheckbox"]');
        elm.prop('checked', event.target.checked);
        $('input[name=ProjectSessionsListCheckbox]').prop('indeterminate', false);

        resetButtonsAfterCheckboxChange();
    }


    onRowCheckboxChange(event) {
        let $root = $(event.target).closest('.ProjectSessionsList-row-header');
        let groupId = $root.data('groupid');

        var rowCheckbox = getrowcheckboxbygroupid(groupId);
        if (rowCheckbox != null) {
            rowCheckbox.indeterminate = false;
        }

        $(`.ProjectSessionsList-itemCheckbox input[data-groupid="${groupId}"]`).each((i, elm) => {
            let $elm = $(elm);
            $elm.prop('checked', event.target.checked);
        });

        //Activate checked row
        $(`tr[data-groupid="${groupId}"] td`).each((i, td) => {
            let $td = $(td);
            event.target.checked ? $td.addClass("activerow") : $td.removeClass("activerow")
        });

        if ($('input[name=ProjectSessionsListCheckbox]').length && $('input[name=ProjectSessionsListItemCheckbox]').length) {
            if ($('input[name=ProjectSessionsListItemCheckbox]').length == $('input[name=ProjectSessionsListItemCheckbox]:checked').length) {
                $('input[name=ProjectSessionsListCheckbox]').prop('checked', true);
                $('input[name=ProjectSessionsListCheckbox]').prop('indeterminate', false);
            }
            else {
                $('input[name=ProjectSessionsListCheckbox]').prop('checked', false);
                $('input[name=ProjectSessionsListItemCheckbox]:checked').length ? $('input[name=ProjectSessionsListCheckbox]').prop('indeterminate', true) : $('input[name=ProjectSessionsListCheckbox]').prop('indeterminate', false)
            }
        }

        resetButtonsAfterCheckboxChange();
    }


    onItemCheckboxChange(event) {
        //if all items in group are now selected then also select the group row              

        let groupId = event.target.dataset.groupid;
        var numChecked = 0;
        var numUnchecked = 0;
        let $parentRow = $(event.target).closest('tr');

        $(`.ProjectSessionsList-itemCheckbox input[data-groupid="${groupId}"]`).each((i, elm) => {
            if (elm.checked) {
                $parentRow.children('td').addClass("activerow");
                numChecked++;
            }
            else {
                $parentRow.children('td').removeClass("activerow");
                numUnchecked++;
            }
        });

        if (event.target.checked) {
            $parentRow.children('td').addClass("activerow");
        }
        else {
            $parentRow.children('td').removeClass("activerow");
        }

        if (numChecked == 0) {
            $(`tr[data-groupid="${groupId}"] td`).each((i, td) => {
                let $td = $(td);
                $td.removeClass("activerow");
            });
            var rowCheckbox = getrowcheckboxbygroupid(groupId);
            if (rowCheckbox != null) {
                rowCheckbox.checked = false;
                rowCheckbox.indeterminate = false;
            }
        }
        if (numUnchecked == 0) {
            var rowCheckbox = getrowcheckboxbygroupid(groupId);
            if (rowCheckbox != null) {
                rowCheckbox.checked = true;
                rowCheckbox.indeterminate = false;
            }
        }
        if (numChecked && numUnchecked) {
            var rowCheckbox = getrowcheckboxbygroupid(groupId);
            if (rowCheckbox != null) {
                rowCheckbox.checked = false;
                rowCheckbox.indeterminate = true;
            }
            $parentRow.siblings(`.ProjectSessionsList-row-header[data-groupid="${groupId}"]`).children('td').each((i, td) => {
                let $td = $(td);
                $td.addClass("activerow");
            });
        }

        if ($('input[name=ProjectSessionsListCheckbox]').length && $('input[name=ProjectSessionsListItemCheckbox]').length) {
            if ($('input[name=ProjectSessionsListItemCheckbox]').length == $('input[name=ProjectSessionsListItemCheckbox]:checked').length) {
                $('input[name=ProjectSessionsListCheckbox]').prop('checked', true);
                $('input[name=ProjectSessionsListCheckbox]').prop('indeterminate', false);
            }
            else {
                $('input[name=ProjectSessionsListCheckbox]').prop('checked', false);
                $('input[name=ProjectSessionsListItemCheckbox]:checked').length ? $('input[name=ProjectSessionsListCheckbox]').prop('indeterminate', true) : $('input[name=ProjectSessionsListCheckbox]').prop('indeterminate', false)
            }
        }

        resetButtonsAfterCheckboxChange();
    }

    onHeaderArrowClick(event) {
        event.preventDefault();

        let $target = $(event.target).closest('.ProjectSessionsList-row-headerArrow');
        let isOpen = $target.hasClass('ti-angle-up');
        let groupId = $target.closest('.ProjectSessionsList-row-header').data('groupid');

        if (isOpen) {
            this.groupRow(groupId);
        } else {
            this.expandRow(groupId);
        }
        //this.sizeChart();
    }

    onGroupAllClick(event) {
        event.preventDefault();

        this.groupAll();
    }

    onExpandAllClick(event) {
        event.preventDefault();
        this.expandAll();
        this.sizeChart();
    }

    onSearchKeyUp(event) {
        let $target = $(event.target);
        let val = $target.val();

        this.filterData(val);
    }

    onGroupingClick(event) {
        event.preventDefault();

        let $root = $(event.target).closest('.quickview');
        let value = $root.find('input[name="group"]:checked').val();

        this.setGroupByColumn(value);

        this.updateList(this.resultData, this.groupByColumn);
        $root.find('.close').trigger('click');
    }

    onGroupingChange(event) {
        event.preventDefault();
        var select = document.getElementById('project-dropdown-group');

        this.setGroupByColumn(select.value);
        if (this.resultData != null) {
            this.updateList(this.resultData, this.groupByColumn);
            this.updateHeadingsColor();
        }
    }


    onFilteringClick(event) {
        event.preventDefault();

        let $root = $(event.target).closest('.quickview');

        let values = [];
        $root.find('input[name="filter"]:checked').each((i, elm) => {
            values.push($(elm).val());
        });

        this.setFilterColumns(values);

        $root.find('.close').trigger('click');
    }

    onCancelGroupingClick(event) {
        event.preventDefault();

        let $root = $(event.target).closest('.quickview');
        $root.find('.close').trigger('click');
    }

    onCancelFilteringClick(event) {
        event.preventDefault();

        let $root = $(event.target).closest('.quickview');
        $root.find('.close').trigger('click');
    }

    onRowBodyClick(event) {
        event.preventDefault();

        let id = $(event.target).closest('.ProjectSessionsList-row-body').attr('data-id');

        window.location.href = DETAIL_URL.replace('{parentId}', this.data.parentid).replace('{id}', id);
    }

    onSingleHeaderRowClick(event) {
        event.preventDefault();

        let id = $(event.target).closest('.ProjectSessionsList-row-header').attr('data-id');

        window.location.href = DETAIL_URL.replace('{parentId}', this.data.parentid).replace('{id}', id);
    }

    onHeadingClick(event) {
        event.preventDefault();


        let $target = $(event.target).closest('.ProjectSessionsList-heading');

        let currentDirection = $target.attr('data-direction') || SortDirection.DESCENDING;

        let newDirection = currentDirection === SortDirection.DESCENDING
            ? SortDirection.ASCENDING
            : SortDirection.DESCENDING;

        this.sortByColumn = $target.data('column');
        this.sortDirection = newDirection;
        this.sortData(this.sortByColumn, this.sortDirection);

        //group the item rows by the wanted groupcolumn, sort the groups and create the html.      
        this.updateList(this.resultData, this.groupByColumn);
        this.afterUpdateActions();
        this.updateHtmlForSortByColumn(this.sortByColumn, this.sortDirection);
    }

    ChangeEmail(data) {

        var select = document.getElementById('mail-selector');
        //let mailTemplateID = select.options[select.selectedIndex].value;
        let mailTemplateID = select.value;

        $('.language-tabs li a').each(function () {
            var languageID = $(this).attr('data-languageid');
            loadMailTemplateInstanceDataForEmail(mailTemplateID, languageID, languageID, false);
        });




    }



}


function getrowcheckboxbygroupid(groupId) {
    //first find the "group" row. This is a sibling of the root/clicked row so we cannot use .closest.   
    var groupRows = document.querySelectorAll('.ProjectSessionsList-row-header');
    var divCheckbox;
    groupRows.forEach(row => {
        if (row.dataset.groupid == groupId) {
            //now find the checkbox in the children (columns) of this row
            divCheckbox = row.querySelector('.ProjectSessionsList-rowCheckbox');
        }
    });
    var checkbox = divCheckbox?.querySelector('[name = "ProjectSessionsListRowCheckbox"]');
    return checkbox;
}



function isNumber(n) {
    return Number(n) === n;
}

function resetButtonsAfterCheckboxChange() {

    var atLeastOneIsChecked = $('input[name="ProjectSessionsListItemCheckbox"]:checked').length > 0;

    if (atLeastOneIsChecked) {
        toggleButtons(true);
    }
    else {
        toggleButtons(false);
    }

}

function toggleButtons(hide) {
    let emailButton = document.getElementById('emailButton');
    let editFieldsButton = document.getElementById('editFieldsButton');
    let deleteButton = document.getElementById('deleteButton');
    let transferButton = document.getElementById('transferSessionsButton');

    if (hide == true) {

        emailButton.disabled = false;
        emailButton.style.cursor = "pointer";
        deleteButton.disabled = false;
        deleteButton.style.cursor = "pointer";
        if (transferButton != null) {
            transferButton.disabled = false;
            transferButton.style.cursor = "pointer";
        }

        editFieldsButton.disabled = false;
        editFieldsButton.style.cursor = "pointer";

    }
    else {
        emailButton.disabled = true;
        emailButton.style.cursor = "not-allowed";
        deleteButton.disabled = true;
        deleteButton.style.cursor = "not-allowed";
        if (transferButton != null) {
            transferButton.disabled = true;
            transferButton.style.cursor = "not-allowed";
        }
        editFieldsButton.disabled = true;
        editFieldsButton.style.cursor = "not-allowed";
    }
}

const EMAIL_COLUMNS = ['participantName', 'languageName', 'subject']


///////////////////////////////////////////////////////////////////////////////////  EMAIL MODAL  ////////////////////////////////////////////////////////////


$('.projectsession-emailmodal').each(function () {


    var page = $(this);
    var nextbutton = $('#emailnextbutton');
    var backbutton = $('#emailbackbutton');
    var sendmailbutton = $('#sendmailbutton');
    var mailtypepane = $('#MailTypePane')[0];
    var summarypane = $('#EmailSummaryPane')[0];
    var confirmpane = $('#EmailConfirmPane')[0];

    var mailtypenav = $('#MailTypeNav')[0];
    var summarynav = $('#EmailSummaryNav')[0];
    var confirmnav = $('#EmailConfirmNav')[0];


    sendmailbutton.attr("style", "display:none");

    page.on('click', '#emailnextbutton', function () {
        if (isPaneActive(mailtypepane)) {
            projectsessions_shownextpane(mailtypepane, summarypane);
            projectsessions_setnavigationcolors(mailtypenav, summarynav);
            backbutton.attr("style", "display:block");
        }
        else if (isPaneActive(summarypane)) {
            projectsessions_shownextpane(summarypane, confirmpane);
            projectsessions_setnavigationcolors(summarynav, confirmnav);
            backbutton.attr("style", "display:block");
            nextbutton.attr("style", "display:none");
            sendmailbutton.attr("style", "display:block");
            var mailType = document.getElementById('mail-selector');
            //$('#emailconfirm-mailtype').html(mailType.options[mailType.selectedIndex].text);
            $('#emailconfirm-mailtype').html(mailType.text);

        }
        else if (isPaneActive(confirmpane)) {

            backbutton.attr("style", "display:block");

        }
        //nextbutton.attr("style", "display:block");
        //backbutton.attr("style", "display:none");

    });

    page.on('click', '#emailbackbutton', function () {
        if (isPaneActive(summarypane)) {
            projectsessions_shownextpane(summarypane, mailtypepane);
            projectsessions_removenavigationcolors(summarynav);
            backbutton.attr("style", "display:none");


        }
        else if (isPaneActive(confirmpane)) {
            projectsessions_shownextpane(confirmpane, summarypane);
            projectsessions_removenavigationcolors(confirmnav);
            nextbutton.attr("style", "display:block");
            sendmailbutton.attr("style", "display:none");
        }


    })

    page.on('click', '#sendmailbutton', function () {

        let customTemplates = {};
        let customMailSubjects = {};

        var mailtype = document.getElementById('mail-selector');
        $('.language-tabs li a').each(function () {
            var languageID = $(this).attr('data-languageid');
            customTemplates[languageID] = $('.tab-pane.fade#' + languageID + ' form .form-group.row .note-editable')[0].innerHTML;
            customMailSubjects[languageID] = $('.tab-pane.fade#' + languageID + ' form .form-group.row #subject')[0].value;
        });
        $.ajax({
            url: EMAIL_SEND_API_URL.replace('{parentId}', PROJECTID),
            type: 'POST',
            contentType: 'application/json',

            data: JSON.stringify({
                Sessions: sessionIDs,
                MailTemplateAliasID: mailtype.value, //this is the mailtemplateid
                customTemplates: customTemplates,
                customMailSubjects, customMailSubjects
            }),

            success: function (resultMessage) {

                app.modaler({
                    html: resultMessage,
                    type: 'center',
                    title: 'Mail sent',
                    size: 'lg',
                    cancelVisible: false,
                    confirmVisible: true,
                    confirmText: 'OK',
                    onConfirm: function (modal) { window.location.reload(false); }
                });
            },
            error: function (e) {
                alert(e);
            }
        });
    });



});






function updateEmailList(data) {
    let html = '';
    var participants = data.sessions.map(x => x.participantName);
    var uniqueParticipants = participants.filter(function (value, index, self) {
        return self.indexOf(value) === index;
    });
    $('#emailconfirm-amount').html(uniqueParticipants.length);
    delete data.languages["$id"];
    const languages = data.languages;
    let languageListHtml = '';
    let languageBodyHtml = '';

    for (var language in languages) {
        var active = language === Object.keys(languages)[0] ? "active show" : "";
        languageListHtml += `
            <li class="nav-item data-languageid="${language}">
                <a class="nav-link lh-1 pt-3 pb-3 ${active}"  data-toggle="tab" href="#${language}" data-languageid="${language}" style="display: block;">
                    <span class="badge badge-sm badge-ring badge-cyan"></span>
                    <small class="fs-14 top-title">
                       ${language}
                    </small>
                    <br />
                </a>
           </li>`

        languageBodyHtml += `<div class="tab-pane fade ${active}" id="${language}">
                                <form>
                                    <div class="form-group row">
                                        <label for="Subject" class="col-1">Subject</label>
                                        <div class="col-11 flex-grow subject-container">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="Body" class="col-1">Body</label>
                                        <div class="col-11 body-container">
                                        </div>
                                    </div>
                                </form>
                            </div>`
    }

    $('.language-tabs').html(languageListHtml);
    $('.mailtemplateinstance-content-tabs').html(languageBodyHtml);
    var select = document.getElementById('mail-selector');
    //let mailTemplateID = select.options[select.selectedIndex].value;
    let mailTemplateID = select.value;
    for (var language in languages) {
        loadMailTemplateInstanceDataForEmail(mailTemplateID, language, false);
    }

    for (var language in languages) {

        //setTimeout(1000);
        //$('.tab-pane.fade#' + language + ' form .form-group.row .note-editable').on('keyup', function () { console.log("TEST?") });
    }



    const groupedData = groupBy(data.sessions, "participantName");
    let i = 0;
    for (var groupKey in groupedData) {
        let group = groupedData[groupKey];
        let values = [];
        for (let i in EMAIL_COLUMNS) {
            let column = EMAIL_COLUMNS[i];

            switch (column) {
                case 'participantName':
                    var plural = group.length > 1 ? "s" : "";
                    const participants = group.map(x => x.participantName);
                    const uniqueParticipants = participants.filter(function (value, index, self) {
                        return self.indexOf(value) === index;
                    });
                    if (uniqueParticipants.length > 1) {
                        values.push(`${group.length} participant` + plural);
                    }
                    else {
                        values.push(uniqueParticipants);
                    }


                    break;

                case 'languageName':
                    values.push(group[0]['languageID']);
                    break;
                case 'subject':
                    const mails = group.map(x => x.participantName);
                    const uniqueMails = mails.filter(function (value, index, self) {
                        return self.indexOf(value) === index;
                    });
                    var plural = uniqueMails.length > 1 ? "s" : "";

                    values.push(`${uniqueMails.length} mail` + plural);




                    //else {
                    //    values.push(uniqueSubjects);
                    //}

                    break;


            }
        }
        html += getRowHeaderHtml(i, values);

        group.forEach((item) => {
            let itemValues = [];
            for (let i in EMAIL_COLUMNS) {
                let column = EMAIL_COLUMNS[i];
                itemValues.push(item[column]);
            }
            html += getItemHtml(i, item['id'], itemValues);
        });

        i++;
    }
    $('.ProjectSessionEmail-items').html(html);
}


function getItemHtml(id, itemId, values, hidden = true) {
    const hiddenClass = hidden ? 'd-none' : '';

    let columnsHtml = '';

    for (let i in EMAIL_COLUMNS) {
        let column = EMAIL_COLUMNS[i];
        let value = EMAIL_COLUMNS[i];

        columnsHtml += `<td class="ProjectSessionsList-column" data-column="${column}">${value}</td>`;
    }

    return `<tr class="ProjectSessionsList-row-body cursor-pointer ${hiddenClass}" data-groupid="${id}" data-id="${itemId}">
                  <td width="1">&nbsp;</td>
                    <div class="ProjectSessionsList-itemCheckbox custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input row-selector" name="ProjectSessionsListItemCheckbox" value="${itemId}" data-groupid="${id}">
                        <label class="custom-control-label"></label>
                    </div>
                  </td>
                  ${columnsHtml}
              </tr>`;
}

function getRowHeaderHtml(id, values) {
    let columnsHtml = '';

    for (let i in EMAIL_COLUMNS) {
        let column = EMAIL_COLUMNS[i];
        let value = values[i];

        columnsHtml += `<td class="ProjectSessionsList-column" data-column="${column}">${value}</td>`;
    }

    return `
            <tr class="ProjectSessionsList-row-header" data-groupid="${id}">
              ${columnsHtml}
          </tr>
        `;
}


function loadMailTemplateInstanceDataForEmail(mailTemplateID, languageID, forceReload) {

    var editPanel = $('#' + languageID + '.tab-pane');
    $('html, body').animate({
        scrollTop: $('.content-language-card').offset().top - 150
    });

    if (editPanel.data('loaded') == true && forceReload == false) {
        return;
    }

    if (editPanel.data('changed') == true) {
        return;
    }

    editPanel.data('loaded', true);

    var req = $.ajax({
        type: 'GET',
        url: '/api/humaninsight/mailtemplate/' + mailTemplateID + '/instance/' + languageID,
        success: function (d) {
            var subjectContainer = editPanel.find('.subject-container');
            var bodyContainer = editPanel.find('.body-container');
            subjectContainer.html("");
            bodyContainer.html("");

            var subjectInput = $('<input type="text" id="subject" name="subject" value="" class="form-control datatype-text " />').appendTo(subjectContainer);
            var bodyInput = $('<textarea id="body" name="body" class="form-control datatype-text datasize-huge summernote" style="height: 500px;"></textarea>').appendTo(bodyContainer);

            if (d != null) {
                subjectInput.val(d.subject);
                bodyInput.val(d.body);

                editPanel.attr('data-mailtemplateinstanceid', d.id);
            }

            Glide.loadTheAdminProviders();
            setEventsForTextField();

        },
        error: function (err) {
        }
    });
}


function setEventsForTextField() {

    setTimeout(3000);

    $('.language-tabs li a').each(function () {
        var languageID = $(this).attr('data-languageid');
        $('.tab-pane.fade#' + languageID + ' form .form-group.row .note-editable').on('keyup', function () {
            changeLanguageBadge(languageID);
        });


    });
}

function changeLanguageBadge(languageID) {
    var badgeItem = $(`.nav-item [data-languageid="${languageID}"] span`);
    badgeItem.removeClass('badge-sm');
    badgeItem.removeClass('badge-ring');

    badgeItem.addClass('badge-md');
    badgeItem.addClass('badge-dot');

}

function projectsessions_removenavigationcolors(nav) {
    nav.classList.remove('processing');
    nav.classList.remove('complete');
}

function projectsessions_setnavigationcolors(navFrom, navTo) {
    navFrom.classList.remove('processing');
    navFrom.classList.add('complete');
    navTo.classList.add('processing');
}

function projectsessions_shownextpane(paneFrom, paneTo) {
    paneFrom.classList.remove('active');
    paneFrom.classList.remove('show');
    paneTo.classList.add('active');
    paneTo.classList.add('show');
}

function isPaneActive(pane) {
    if (pane.classList.contains('active') && pane.classList.contains('show')) {
        return true;
    }
    else {
        return false;
    }

}
