import { Vector3, ArrowHelper } from 'three';
import MaterialObject from '../../classes/materialObject.js'

export default class Compass extends MaterialObject {
    constructor(length, color, {x = null, y = null, z = null}={x:null, y:null, z:null},{rx = null, ry = null, rz = null}={rx:null, ry:null, rz:null}) {
        super();

        const dir = new Vector3(0, 0, 0);
        dir.normalize();

        const origin = new Vector3(0, 0, 0);

        this.obj = new ArrowHelper(dir, origin, length, color);

        this.setPosition(x, y, z);
        this.setRotation(rx, ry, rz);

        return this.obj;
    }
}