export default class MaterialObject {
    constructor() {
        this.rotate90 = () => Math.PI / 2;
        this.obj = null;
    }

    setPosition(x=null, y=null, z=null) {
        if(this.obj === null) {
            throw Error('Object not set');
        }
        if(x !== null) {
            this.obj.position.x = x;
        }
        if(y !== null) {
            this.obj.position.y = y;
        }
        if(z !== null) {
            this.obj.position.z = z;
        }
    }

    setRotation(x=null, y=null, z=null) {
        if(this.obj === null) {
            throw Error('Object not set');
        }

        if(x !== null) {
            this.obj.rotation.x = x;
        }
        if(y !== null) {
            this.obj.rotation.y = y;
        }
        if(z !== null) {
            this.obj.rotation.z = z;
        }
    }
}