/* eslint-disable */
import "../sass/style.scss";
import Router from './router.js';
import Helper from './helper.js';

/* You can test a visualisation by uncommenting these import statements, and below you will find a test script for a visualisation. 
Run 'npm run start' and you will get a live environment
/* Uncomment for testing visualisations */
import AEMCube from './visualization/entrypoints/AEMCube.js';
import SCurve from './visualization/entrypoints/SCurve';
import NumericalValue from './visualization/entrypoints/NumericalValue';
import Scale from './visualization/entrypoints/Scale';
import CameraPreset from './visualization/enums/cameraPreset.js';
import RenderType from './visualization/enums/renderType.js';
import PointerType from './visualization/enums/pointerType.js';
/* Uncomment for testing visualisations */

import modulesIncludes from '../../build/dist/webpack-includes-modules.js';
import pointerType from "./visualization/enums/pointerType";

var modulesJson = require('../../build/dist/modules.json');

$(function () {
    //load modules via router
    var router = new Router(modulesIncludes, modulesJson.modules);
    window.router = router;

    router
        .registerTransient("QuickView")
        .registerTransient("ParticipantUpload")
        .registerTransient("ProjectGroupView")
        .registerTransient("ItemDetail")
        .registerTransient("VisualizationDetail")
        .registerTransient("InstrumentItemList")
        .registerTransient("ProjectSessionEmailModal")
        .registerTransient("ProjectSessionsList")
        .registerTransient("ConsultantDetail")
        .registerTransient("BasicForm")
        .registerTransient("InstrumentReportDetail")
        

        .dom(".QuickView", "QuickView")
        .dom(".ParticipantUpload", "ParticipantUpload")
        .dom("#ProjectGroupView", "ProjectGroupView")
        .dom("#ItemDetail", "ItemDetail")
        .dom("#VisualizationDetail", "VisualizationDetail")
        .dom(".ProjectSessionEmailModal", "ProjectSessionEmailModal")
        .dom(".ProjectSessionsList", "ProjectSessionsList")
        .dom("#InstrumentItemList", "InstrumentItemList")
        .dom("#ConsultantDetail", "ConsultantDetail")
        .dom(".BasicForm", "BasicForm")
        .dom(".InstrumentReportDetail", "InstrumentReportDetail");
    

    //Uncomment for AEMCube test purposes 
    //$('body').prepend('<div id="canvasContainer" style="width: 1200px; height: 1000px; margin-top: 100px; margin-left: 100px;">');
    //if (document.getElementById('canvasContainer') !== null) {
    //    var aemCube = new AEMCube({
    //        stage: {
    //            cameraPreset: CameraPreset.CUBE_ANGLE
    //        },
    //        data: {
    //            pointers: [
    //                {
    //                    name: '1',
    //                    color: '#dd2e6a',
    //                    type: PointerType.EXTERNAL,
    //                    percentiles: {
    //                        x: 50,
    //                        y: 75,
    //                        z: 75
    //                    }
    //                },
    //                {
    //                    name: '2',
    //                    color: '#fff332',
    //                    type: PointerType.INTERNAL,
    //                    percentiles: {
    //                        x: 75,
    //                        y: 50,
    //                        z: 25
    //                    }
    //                },
    //                {
    //                    name: '3',
    //                    color: '#8e4a6d',
    //                    type: PointerType.INTERNAL,
    //                    percentiles: {
    //                        x: 25,
    //                        y: 25,
    //                        z: 50
    //                    }
    //                },
    //                {
    //                    name: '4',
    //                    color: '#4b35d7',
    //                    type: PointerType.EXTERNAL,
    //                    percentiles: {
    //                        x: 25,
    //                        y: 50,
    //                        z: 75
    //                    }
    //                },
    //                {
    //                    name: '5',
    //                    color: '#ff9d25',
    //                    type: PointerType.INTERNAL,
    //                    percentiles: {
    //                        x: 20,
    //                        y: 30,
    //                        z: 40
    //                    }
    //                },
    //                {
    //                    name: '6',
    //                    color: '#2cd63b',
    //                    type: PointerType.EXTERNAL,
    //                    percentiles: {
    //                        x: 80,
    //                        y: 60,
    //                        z: 40
    //                    }
    //                },
    //            ]
    //        }
    //    });

    //    window.AEMCube = aemCube;
    //}

    //pointer.name, pointer.color, pointer.type, pointer.percentiles.x, pointer.percentiles.y, pointer.percentiles.z

    /*

    /*Uncomment for SCurve test purposes 
    $('body').prepend('<div id="canvasContainer" style="width: 500px; height: 500px; margin-top: 200px;">');
    if (document.getElementById('canvasContainer') !== null) {
        var sCurve = new SCurve({
            debug: false,
            debugOptions: {
                showAxes: false,
                showGrid: true
            },
            renderType: RenderType.WEBGL2,
            stage: {
                cameraPreset: CameraPreset.SCURVE_FRONT
            },
            data: {
                scales: {
                    x: {
                        name: "X Scale"
                    },
                    y: {
                        name: "Y Scale"
                    }
                },
                pointers: [
                    {
                        name: "user1",
                        color: 0xb12524,
                        type: PointerType.INTERNAL,
                        percentile: { y: 75 }
                    },
                    {
                        name: "company2",
                        color: 0x0a5692,
                        type: PointerType.EXTERNAL,
                        percentile: { x: 75 }
                    }
                ]
            }
        });

        window.sCurve = sCurve;
    }
    /**/

    /*Uncomment for NumericValue test purposes 
    $('body').prepend('<div id="canvasContainer" style="width: 500px; height: 500px; margin-top: 200px;">');
    if (document.getElementById('canvasContainer') !== null) {
        var numericalValue = new NumericalValue({
            debug: false,
            debugOptions: {
                showAxes: false,
                showGrid: true
            },
            renderType: RenderType.WEBGL2,
            stage: {
                cameraPreset: CameraPreset.NUMERICALVALUE_FRONT
            },
            data: {
                shapes: [{
                    color: 0x437832,
                    index: 75,
                    offsetLeft: 5,
                    offsetRight: 20,
                    offsetTop: 10,
                    offsetBottom: 10
                }]
            }
        });

        window.NumericalValue = numericalValue;
    }
    /**/

    /*Uncomment for Scale test purposes 
    $('body').prepend('<div id="canvasContainer" style="width: 500px; height: 500px; margin-top: 200px;">');
    if (document.getElementById('canvasContainer') !== null) {
        var scale = new Scale({
            debug: false,
            debugOptions: {
                showAxes: false,
                showGrid: true
            },
            renderType: RenderType.WEBGL2,
            stage: {
                cameraPreset: CameraPreset.SCALE_FRONT
            },
            data: {
                pointers: [
                    {
                        name: "user1",
                        color: 0xa00114,
                        type: PointerType.INTERNAL,
                        scaleStep: 1
                    },
                    {
                        name: "user1",
                        color: 0x114f8d,
                        type: PointerType.EXTERNAL,
                        scaleStep: 5
                    }
                ]
            }
        });

        window.Scale = scale;
    }
    /**/

});