import { Group } from 'three';
import MaterialObject from '../../classes/materialObject.js';
import Line from '../line.js';
import Ball from '../ball.js';
import Piramide from '../piramide.js';
import PointerType from '../../enums/pointerType.js';
import Disc from '../disc.js';

export default class Pointer extends MaterialObject {
    constructor(length, lineSize, tipSize, lineColor, tipColor, type=PointerType.INTERNAL, {x = 0, z = 0}={x:0, z:0}, y=null) {
        super();

        this.obj = new Group();

        this.obj.add(
            new Line(lineSize, length, lineColor, { x: 0, y: length * .5, z: 0 }, {}, false)
        );

        if(length > 30) {
            this.obj.add(new Disc(lineSize * 2, lineSize * 2, lineColor, { y: 25 }, false));
        }
        if(length > 55) {
            this.obj.add(new Disc(lineSize * 2, lineSize * 2, lineColor, { y: 50 }, false));
        }
        if(length > 80) {
            this.obj.add(new Disc(lineSize * 2, lineSize * 2, lineColor, { y: 75 }, false));
        }

        let tip = null;

        switch(type) {
            case PointerType.EXTERNAL:
                tip = new Piramide(tipSize, tipSize * 2, tipColor, { y: length }, false, true);
                break;
            case PointerType.INTERNAL:
            default:
                tip = new Ball(tipSize, tipColor, { y: length }, false);
                break;
        }

        this.obj.add(tip);
        
        this.setPosition(x, y, z);

        return this.obj;
    }
}