import { Group, BufferGeometry, LineBasicMaterial, Line, SplineCurve, Vector2 } from 'three';
import MaterialObject from '../../classes/materialObject.js'

function sigmoid(x) {
    return 1 / (1 + Math.pow(Math.exp(1), -x));
}

export default class SCurve extends MaterialObject {
    constructor({x = null, y = null, z = null}={x: null, y: null, z: null}, {rx = null, ry = null, rz = null}={rx: null, ry: null, rz: null}) {
        super();

        const sigmoidData = [
            { x: 0, y: 0 },
            { x: 100, y: 100 }
        ];

        this.obj = new Group();

        this.points = this.getSigmoidPathData(sigmoidData);
        const curve = new SplineCurve(this.points);
        const pathPoints = curve.getPoints(100);
        const geometry = new BufferGeometry().setFromPoints(pathPoints);
        const material = new LineBasicMaterial({ color: 0x000000 });
        const splineObject = new Line(geometry, material);
        
        this.obj.add(splineObject);

        this.setPosition(x, y, z);
        this.setRotation(rx, ry, rz);
    }

    getSigmoidPathData(scaleData) {
        const x1 = scaleData[0].x;
        const x2 = scaleData[1].x;
        const y1 = scaleData[0].y;
        const y2 = scaleData[1].y;

        const PathData = [];

        for (let z = -6; z <= 6; z += .05) {
            const sigmoidX = x1 + (x2 - x1) * ((z + 6) / 12);
            const sigmoidY = y1 + (y2 - y1) * sigmoid(z);

            PathData.push(
                new Vector2(sigmoidX, sigmoidY)
            );
        }
        return PathData;
    }

    getXForY(y) {
        var index = this.points.reduce(function (r, a, i, aa) {
                return i && Math.abs(aa[r].y - y) < Math.abs(a.y - y) ? r : i;
            }, -1);

        return this.points[index].x;
    }

    getYForX(x) {
        var index = this.points.reduce(function (r, a, i, aa) {
            return i && Math.abs(aa[r].x - x) < Math.abs(a.x - x) ? r : i;
        }, -1);

        return this.points[index].y;
    }
}