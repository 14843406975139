import HandlebarsTemplate from "./HandlebarsTemplate";

export default class VisualizationDetail {
    constructor(elm) {
        var $elm = $(elm);
        var editor = $('#VisualizationEditor');

        this.controllerurl = $elm.data('apiurlbase');
        this.id = $elm.data('rowid');

        this.visualizationEditor = new HandlebarsTemplate(editor);

        this.getVisualization(this.id)
            .done(this.loadTemplate.bind(this));
    }

    getVisualization(id) {
        if (id === false) return $.when();

        var visualizationData = $.ajax({
            url: this.controllerurl + '/' + id,
            type: 'GET'
        });

        return visualizationData;
    }

    loadTemplate(data) {
        this.visualizationEditor.render(data);
    }
}
