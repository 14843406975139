import { GridHelper } from 'three';
import MaterialObject from '../classes/materialObject.js'

export default class GridPlane extends MaterialObject {
    constructor(size, divisions, colorCenterLine, colorGrid, {x = null, y = null, z = null}={x:null, y:null, z:null},{rx = null, ry = null, rz = null}={rx:null, ry:null, rz:null}) {
        super();
        
        this.obj = new GridHelper( size, divisions, colorCenterLine, colorGrid);
    
        this.setPosition(x, y, z);
        this.setRotation(rx, ry, rz);

        return this.obj;
    }
}