export default class BasicForm {
    constructor(elm) {
        this.$elm = $(elm);
        this.controllerUrl = this.$elm.data('apiurlbase');
        this.listUrl = this.$elm.data('listurl');
        this.$upload = this.$elm.find('.file-group.show-files input[type=file]')
        this.setEventListeners();
    }

    setEventListeners() {
        if(this.controllerUrl) {
            this.$elm.on('submit', this.onFormSubmit.bind(this));
        }
        this.$upload.each(function(){
            this.addEventListener("change", function() {
                //var button = this.previousElementSibling;
                var $this = $(this);
                $this.next('.file-uploaded').remove()
                $this.after("<ul class='file-uploaded'><li>" + this.files[0].name + "</li></ul>");
            });
        });
    }

    onFormSubmit(event) {
        event.preventDefault();

        $.ajax({
            type: 'post',
            url: this.controllerUrl + '/rows',
            data: this.$elm.serialize(),

        })
            .done(this.redirectSuccess.bind(this))
            .fail(this.errorMessage.bind(this));
    }

    redirectSuccess(response) {
        window.location.replace(this.listUrl);
    }

    errorMessage(response) {
        this.$elm.prepend(`<div class="callout callout-danger mb-0" role="alert">${response.responseText}</div>`);
    }
}
