import Helper from '../helper'

export default class ParticipantUpload {
    constructor(elm) {
        this.elm = elm;
        this.$elm = $(elm);

        this.setEventListeners();
    }

    setEventListeners() {
        this.$elm.on('submit', this.onTriggerUpload.bind(this));
    }

    onTriggerUpload(event) {
        event.preventDefault();

        var form = this.elm;
        var url = this.$elm.attr('action');

        $.ajax({
            url: url,
            type: "POST",
            data: new FormData(form),
            contentType: false,
            cache: false,
            processData: false,
            success: this.showDialogComplete.bind(this)
        });
    }

    showDialogComplete(data) {
        app.modaler({
            html: data.text,
            type: 'center',
            title: 'Upload',
            size: 'lg',
            cancelVisible: true,
            confirmVisible: true,
            confirmText: 'OK!'
        });

        // refresh datatable
        Helper.glideReloadTable($('#main-table'));
    }
}