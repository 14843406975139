import HandlebarsTemplate from "./HandlebarsTemplate";
import * as Const from '../const';

export default class ItemDetail {
    constructor(elm) {
        var $elm = $(elm);
        var itemSettings = $('#InstrumentItemSettings');

        this.controllerurl = $elm.data('apiurlbase');
        this.controllerurltext = $elm.data('apiurltext');
        this.listurl = $elm.data('listurl');
        this.id = $elm.data('rowid') || false;
        this.itemSettings = new HandlebarsTemplate(itemSettings);
        this.textContainer = $elm.find('#text-container').first();
        this.templates = $elm.find('#text-templates').first();
        this.languageId = localStorage.getItem(Const.LANGUAGE_STORAGE_KEY) || Const.LANGUAGE_DEFAULT_VAL;

        this.getInstrumentItem(this.id)
            .done(this.loadTemplate.bind(this));
    }

    getInstrumentItem(id) {
        if (id === false) return $.when();

        var itemData = $.ajax({
            url: this.controllerurl + '/' + id,
            type: 'GET'
        });
        this.languageData = $.ajax({
            url: this.controllerurltext + '/' + this.languageId,
            type: 'GET'
        });

        return itemData;
    }

    loadTemplate(data) {
        $('#item-language-id').attr('value', this.languageId);
        this.itemSettings.render(data);

        this.itemTypeSelect = $('#InstrumentItemTypeID');
        var con = this;

        // register events
        this.itemSettings.container.on('submit', this.saveRecord.bind(this));

        this.itemTypeSelect.on('change', function () {
            con.onChangeItemType();
            con.textEditor.renderWhen(con.languageData);
        });
        $('#form-items').on('submit', this.onSaveTranslation.bind(this));
        $('#item-language-id').on('change', this.onChangeTranslationLanguage.bind(this));

        this.onChangeItemType();
        this.textEditor.renderWhen(this.languageData);
    }

    onChangeItemType() {
        var itemType = this.itemTypeSelect.val();
        var template = $('#text-' + itemType);
        this.textEditor = new HandlebarsTemplate(template, this.textContainer);
    }

    onChangeTranslationLanguage(event) {
        var language = $('#item-language-id').val();

        localStorage.setItem(Const.LANGUAGE_STORAGE_KEY, language);
        this.languageData = $.get(this.controllerurltext + '/' + language);
        this.textEditor.renderWhen(this.languageData);
    }

    saveRecord(event) {
        event.preventDefault();

        var form = this.itemSettings.container.find('form');
        var url = this.controllerurl;
        var method = 'POST';

        if (this.id != false) {
            // update instead
            url = url + '/' + this.id;
            method = 'PUT';
        }

        $.ajax({
            type: method,
            url: url,
            data: form.serialize(),
            success: this.onSaveSuccess.bind(this)
        });
    }

    onSaveSuccess(event) {
        event.preventDefault();
        app.toast('Changes saved');
        if (this.listurl) window.location.href = this.listurl;
    }

    onSaveTranslation(event) {
        event.preventDefault();
        var $form = $('#form-items');
        var language = $('#item-language-id').val();

        $.ajax({
            url: this.controllerurltext + '/' + language,
            type: 'POST',
            data: $form.serialize(),
            success: this.onSaveTranslationSuccess.bind(this)
        });
    }

    onSaveTranslationSuccess(event) {
        app.toast('Changes saved');
    }
}
