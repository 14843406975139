import HandlebarsTemplate from "./HandlebarsTemplate";
import * as Const from '../const';

export default class InstrumnetReportDetail {
    constructor(elm) {
        var reportTextEditTemplate = $('#ReportTextEditTemplate');

        this.$elm = $(elm);
        this.controllerurl = this.$elm.data('apiurlbase');
        this.editTemplate = new HandlebarsTemplate(reportTextEditTemplate).getTemplate();
        this.reportId = this.$elm.data('report-id');
        this.reportElementTypeID = this.$elm.data('element-type-id');
        this.currentLanguageId = localStorage.getItem(Const.LANGUAGE_STORAGE_KEY) || Const.LANGUAGE_DEFAULT_VAL;
        this.currentKey = this.$elm.data('show-alias');
        this.editContainer = this.$elm.find('#editor-container');
        this.editorForm = $('#editor-form');

        this.initView();
        this.setEventListeners();
    }

    initView() {
        $('#item-language-id').val(this.currentLanguageId);
        if (this.currentKey !== "") this.showText(this.currentKey);
    }

    setEventListeners() {
        this.$elm.find('#ReportElementListContent').on('click', this.onSelectTab.bind(this));
        this.$elm.find('#item-language-id').on('change', this.onSelectLanguage.bind(this));
        this.$elm.find('button.save').on('click', this.saveText.bind(this));
    }

    onSelectLanguage(ev) {
        var languageId = ev.currentTarget.value;
        this.currentLanguageId = languageId;
        localStorage.setItem(Const.LANGUAGE_STORAGE_KEY, languageId);

        this.editContainer.html('');
        this.showText(this.currentKey);
    }

    onSelectTab(ev) {
        this.currentKey = $(ev.target)
            .closest('a')
            .data('key');

        this.showText(this.currentKey);
    }

    loadText(key) {
        var template = this.editTemplate;
        var request = $.ajax({
            type: 'get',
            url: this.controllerurl + '/' + this.reportId + '/text/' + this.currentLanguageId + '/' + key
        });

        return request.then(function (text) {
            var html = template({
                key: key,
                text: text
            });

            return $(html);
        });
    }

    saveText(ev) {
        // todo get current textfield
        var request = $.ajax({
            type: 'post',
            data: this.editorForm.serialize(),
            url: this.controllerurl + '/' + this.reportId + '/text/' + this.currentLanguageId
        });

        request.done(function () { app.toast('Saved!'); });
    }

    showText(key) {
        // add here
        var editor = this.editContainer.find('#editor-' + key).first();
        var container = this.editContainer;

        if (editor.length === 0) {
            editor = this.loadText(key);
        }

        $.when(editor).then(function (element) {
            container.children().hide();
            element.show();
            container.append(element);
        });

        $('html, body').animate({
            scrollTop: this.editContainer.offset().top - 200
        });
    }
}
