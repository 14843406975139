import { Group } from 'three';
import MaterialObject from '../../classes/materialObject.js';
import Plane from '../plane.js';
import GridPlane from '../gridPlane.js';

export default class Floor extends MaterialObject {
    constructor(width, height, color, gridColor, {x = null, y = null, z = null}={x:null, y:null, z:null},{rx = null, ry = null, rz = null}={rx:null, ry:null, rz:null}) {
        super();

        console.log(width, height)

        this.obj = new Group();
        
        const plane = new Plane(width, height, color, {}, {rx: this.rotate90()});
        const grid = new GridPlane(width, 4, gridColor, gridColor, {y: 0.1});

        this.obj.add(plane);
        this.obj.add(grid);

        this.setPosition(x, y, z);
        this.setRotation(rx, ry, rz);

        return this.obj;
    }
}