import merge from 'webpack-merge';
import { Mesh, Vector3, Geometry, LineBasicMaterial, Line, SphereGeometry, MeshBasicMaterial } from 'three';

import Visualization from '../classes/visualization.js';
import MySCurve from '../objects/compoundObjects/scurve.js';
import ScaleLines from '../objects/compoundObjects/scaleLines.js';
import Ball from '../objects/ball.js';
import Piramide from '../objects/piramide.js';
import PointerType from '../enums/pointerType.js';

const DEFAULTS = {
    data: {

    }
};


export default class SCurve extends Visualization {
    constructor(_settings={}) {
        let settings = merge(DEFAULTS, _settings);
        super(settings);

        this.settings = settings;

        this.y = 0;
        this.yDirectionIsUp = true;

        this.scurve = new MySCurve();
        this.stage.scene.add(this.scurve.obj);

        this.scaleLines = new ScaleLines(.1, 100, 0x392930);
        this.stage.scene.add(this.scaleLines.obj);

        if (settings.debug) {
            this.drawYAxisHelper();
            this.drawXAxisHelper();
        }

        this.pointers = new Map();
        
        this.init();
        this.ready();
    }

    init() {
        if(typeof this.settings.data.pointers !== "undefined") {
            for (let pointer of this.settings.data.pointers) {
                this.addPointer(pointer);
            }
        }
    }

    addPointer(pointer) {
        if (typeof pointer.percentile.x !== "undefined") {
            const obj = this.getPointForX(pointer.percentile.x, pointer.color, pointer.type);
            this.stage.scene.add(obj);
            this.pointers.set(pointer.name, obj);
        }

        if (typeof pointer.percentile.y !== "undefined") {
            const obj = this.getPointForY(pointer.percentile.y, pointer.color, pointer.type);
            this.stage.scene.add(obj);
            this.pointers.set(pointer.name, obj);
        }
    }

    removePointer(name) {
        this.pointers.remove(name);
    }

    getPointForY(y, color=0x0000ff, type=PointerType.INTERNAL) {
        const x = this.scurve.getXForY(y);

        let point = null;
        switch (type) {
            case PointerType.INTERNAL:
                point = new Ball(2, color, { x: x, y: y });
                break;
            case PointerType.EXTERNAL:
                point = new Piramide(3, 3, color, { x: x, y: y });
                break;
        }

        return point;
    }

    getPointForX(x, color = 0x0000ff, type = PointerType.INTERNAL) {
        const y = this.scurve.getYForX(x);

        let point = null;
        switch (type) {
        case PointerType.INTERNAL:
            point = new Ball(2, color, { x: x, y: y });
            break;
        case PointerType.EXTERNAL:
            point = new Piramide(3, 3, color, { x: x, y: y });
            break;
        }

        return point;
    }

    //onFrameExecution() {
    //}

    drawPointHelper() {
        const geometry = new SphereGeometry(2, 32, 32);
        const material = new MeshBasicMaterial({ color: 0xff0000 });
        this.target = new Mesh(geometry, material);
        this.target.position.y = this.y;
        this.target.position.x = this.scurve.getXForY(this.y);
        this.stage.scene.add(this.target);
    }

    drawYAxisHelper() {
        const geometry = new Geometry();
        geometry.vertices.push(new Vector3(0, 0, 0));
        geometry.vertices.push(new Vector3(0, 100, 0));
        const material = new LineBasicMaterial({ color: 0xff0000 });
        this.stage.scene.add(
            new Line(geometry, material)
        );

        for (let y = 0; y <= 100; y += 10) {
            var g = new Geometry();
            g.vertices.push(new Vector3(-10, y, 0));
            g.vertices.push(new Vector3(10, y, 0));
            var m = new LineBasicMaterial({ color: 0xff0000 });
            this.stage.scene.add(
                new Line(g, m)
            );
        }
    }

    drawXAxisHelper() {
        const geometry = new Geometry();
        geometry.vertices.push(new Vector3(0, this.y, 0));
        geometry.vertices.push(new Vector3(100, this.y, 0));
        const material = new LineBasicMaterial({ color: 0x0000ff });
        this.stage.scene.add(
            new Line(geometry, material)
        );
    }
}