import { ConeGeometry, MeshBasicMaterial, Mesh, EdgesGeometry, LineBasicMaterial, LineSegments, MeshPhysicalMaterial } from 'three';
import MaterialObject from '../classes/materialObject.js'

export default class Piramide extends MaterialObject {
    constructor(radius, height, color, { x = null, y = null, z = null } = { x: null, y: null, z: null }, basicMat = false, edges = false) {
        super();
        
        const geometry = new ConeGeometry(radius, height, 3);
        const material = basicMat ? new MeshBasicMaterial({ color: color }) : new MeshPhysicalMaterial({ color: color })
        this.obj = new Mesh(geometry, material);

        if (edges) {
            const geo = new EdgesGeometry(this.obj.geometry);
            const mat = new LineBasicMaterial({ color: '#ffffff' });
            const wireframe = new LineSegments(geo, mat);
            this.obj.add(wireframe);
        }
    
        this.setPosition(x, y, z);

        return this.obj;
    }
}

function shadeColor(color, percent) {

    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
    var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
    var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

    return "#" + RR + GG + BB;
}