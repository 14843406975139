
export default class Helper {

    static initFastselect($selector) {
        $selector.each(function (index, elm) {
            var id = elm.value;
            var url = $(elm).data('url');

            // fastselect is not initialized
            if ($(elm).data('fastselect') === undefined) {

                if (id) {
                    $.get(url + `&id=${id}`).done(function (response) {
                        var valueText = response[0] !== undefined ? response[0].text : '';
                        $(elm).fastselect({ valueText: valueText });
                    });
                } else {
                    $(elm).fastselect();
                }
            }
        });
    }

    static glideReloadTable($selector) {
        $selector.data('instance').loadData(false, true);
    }


}
