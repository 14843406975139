export default class Transient {
    constructor(elm) {
        this.elm = elm;
        
        console.log('load transient for: ', elm);
    }

    executeFunction() {
        console.log('executeFunctions for: ', this.elm);
    }
}