import merge from 'webpack-merge';
import Visualization from '../classes/visualization.js';
import Line from '../objects/line.js';
import MyShape from '../objects/shape.js';


const DEFAULTS = {
    visualization: {
        scaleStart: 0,
        scaleEnd: 100,
        scaleRender: 100,
        colors: {
            line: 0x162625
        }
    }
};

DEFAULTS.visualization.scale = DEFAULTS.visualization.scaleEnd - DEFAULTS.visualization.scaleStart;

export default class AEMCube extends Visualization {
    constructor(_settings={}) {
        let settings = merge(DEFAULTS, _settings);
        super(settings);

        const line = new Line(.4, 100, settings.visualization.colors.line, {x: 50, y: 50}, {rz: Math.PI/2});
        this.stage.scene.add(line);

        const shape = new MyShape(settings.data.shapes[0].index, settings.data.shapes[0].offsetLeft, settings.data.shapes[0].offsetRight, settings.data.shapes[0].offsetTop, settings.data.shapes[0].offsetBottom, {x:0,y:50, z: 2}, {}, settings.debug);
        this.stage.scene.add(shape);

        this.ready();
    }

    //onFrameExecution() {
    //}
}