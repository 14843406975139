import { PlaneGeometry, Mesh, DoubleSide, MeshBasicMaterial, MeshPhysicalMaterial } from 'three';
import MaterialObject from '../classes/materialObject.js'

export default class Plane extends MaterialObject {
    constructor(width, height, color, { x = null, y = null, z = null }, { rx = 0, ry = 0, rz = 0 }, basicMat = false) {
        super();
        
        const geometry = new PlaneGeometry(width, height, 32);
        const material = basicMat ? new MeshBasicMaterial({ color: color, side: DoubleSide }) : new MeshPhysicalMaterial({ color: color, side: DoubleSide });
        
        this.obj = new Mesh(geometry, material);

        this.setPosition(x, y, z);
        this.setRotation(rx, ry, rz);

        return this.obj;
    }
}